@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;900&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
@import url("http://fonts.googleapis.com/css?family=Roboto");

/* font-family: 'Inter', sans-serif;
font-family: 'Poppins', sans-serif;
font-family: 'Rubik', sans-serif; */
/* ============== ROOT VARIABLES================== */
* {
  font-family: "Roboto", Helvetica, sans-serif;
}

.App {
  filter: blur(3px);
  pointer-events: none;
  cursor: default;
}

/* Absolute Center Spinner */
.loader-class {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 0.2s;
  width: 100vw;
  height: 100vh;
}

/* Transparent Overlay */
/* .loader-class:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
} */

:root {
  --red-color: #dc332e;
  --red-gradient: linear-gradient(94.83deg, #dc332e -1.9%, #bf181d 99.19%);
  --heading-black-color: #181818;
  --text-black-color: #333333;
  --white-color: #fff;
  --black-color: #000;
  --border-radius-1: 10px;

  --trans: all 0.3s ease-in-out;
}

.card-border-radius {
  border-radius: var(--border-radius-1);
}

/* ===============BACKGROUND COLORS================== */
.bg-red-gradient {
  background: linear-gradient(94.83deg, #dc332e -1.9%, #bf181d 99.19%);
}

.bg-red-color {
  background-color: var(--red-color);
}

/* ===============FONT COLORS================== */
.text-black {
  color: var(--black-color);
}

.text-heading-black {
  color: var(--heading-black-color);
}

.text-black-1 {
  color: var(--text-black-color);
}

.text-white {
  color: var(--white-color);
}

.text-red {
  color: var(--red-color);
}

/* ===============FONT SIZES================== */
.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 0.88rem;
}

.fs-15 {
  font-size: 0.94rem;
}

.fs-16 {
  font-size: 1rem;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}

.fs-28 {
  font-size: 28px;
}

.fs-30 {
  font-size: 30px;
}

/* ===============FONT WEIGHTS================== */
.fw-2 {
  font-weight: 200;
}

.fw-3 {
  font-weight: 300;
}

.fw-4 {
  font-weight: 400;
}

.fw-5 {
  font-weight: 500;
}

.fw-6 {
  font-weight: 600;
}

.fw-7 {
  font-weight: 700;
}

.fw-8 {
  font-weight: 800;
}

.white-btn {
  font-size: 16px;
  line-height: 24px;
  background: var(--white-color);
  border-radius: 80px;
  padding: 13px 60px;
  color: var(--red-color);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.14);
  border: 0px;
}

.btn-btn {
  font-size: 16px;
  line-height: 24px;
  background: linear-gradient(94.83deg, #dc332e -1.9%, #dc332e 99.19%);
  border-radius: 80px;
  padding: 13px 60px;
  color: var(--white-color);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.14);
  border: 0px;
}

.btn-btn:hover {
  color: #dc332e;
  background: #fff;
}

.btn-btn:active {
  color: var(--white-color);
  background: linear-gradient(94.83deg, #dc332e -1.9%, #dc332e 99.19%);
}

.red-btn {
  font-size: 16px;
  line-height: 24px;
  background: linear-gradient(94.83deg, #dc332e -1.9%, #bf181d 99.19%);
  border-radius: 80px;
  padding: 13px 60px;
  color: var(--white-color);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.14);
  border: 0px;
}

.red-btn:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.red-btn :active {
  color: var(--white-color);
}

.product-btn-red {
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  background: #dc332e;
  border-radius: 9px;
  padding: 17px 80px;
  border: 2px solid #dc332e;
  text-decoration: none;
}

.product-btn-red:hover {
  color: #fff;
}

.product-btn-white:hover {
  color: #dc332e;
}

.product-btn-white {
  display: inline-block;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #dc332e;
  background: #fff;
  border: 2px solid #dc332e;
  border-radius: 9px;
  padding: 17px 80px;
  text-decoration: none;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a,
a:hover,
a:focus {
  color: #333;
  text-decoration: none;
  transition: all 0.3s;
}

ul,
li {
  text-decoration: none;
  list-style: none;
}

form label {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1b1f23;
}

form input.form-control {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* color: rgba(102, 112, 133, 0.5); */
  padding: 12px 14px;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
}

form .form-select {
  /* font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(102, 112, 133, 0.5);
    padding: 12px 14px;
    border: 1px solid #F2F2F2;
    border-radius: 8px;
    appearance: listbox; */
  height: 48px;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
}

/* Topbar css */

.topbar {
  padding: 22px 0;
}

.topbar ul {
  margin-bottom: 0;
  padding-left: 0;
}

.topbar ul li {
  color: var(--white-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  padding: 0 12px;
}

.topbar ul li i {
  width: 25px;
  height: 25px;
  background-color: var(--white-color);
  color: var(--red-color);
  text-align: center;
  border-radius: 25px;
  line-height: 25px;
  font-size: 12px;
  font-weight: 800;
}
/* container */
@media screen and (max-width: 1400px) {
  .container {
    max-width: 1400px;
  }
}

/* Header Css */
.header-container {
  padding: 12px 0;
  background-color: #f5f5f5;
}

.header-container .header-right li.nav-item {
  margin: 0px 10px;
}

.header-container .header-right li.nav-item a {
  font-size: 14px;
  font-weight: 400;
  color: var(--black-color);
}

.header-container .header-right li.nav-item .nav-link:hover {
  color: var(--red-color);
}

.header-container .header-right li.nav-item .active {
  color: var(--red-color);
  font-weight: 600;
}

/* Footer css */
.footer {
  background: url(../images/footer-bg.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 106px 0 70px 0;
  position: relative;
  /* margin-top: auto; */
}

.footer i {
  width: 25px;
  height: 25px;
  background-color: var(--white-color);
  color: var(--red-color);
  text-align: center;
  border-radius: 25px;
  line-height: 25px;
  font-size: 12px;
  font-weight: 800;
}

.footer h2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 36px;
  color: var(--white-color);
}

.footer ul {
  padding-left: 0;
}

.footer li {
  margin: 13px 0;
}

.footer li a {
  font-size: 14px;
  font-weight: 400;
  color: var(--white-color);
  line-height: 28px;
}

.footer-col-one ul li {
  margin: 25px 0;
  color: var(--white-color);
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  /* display: flex; */
}

.footer-col-one ul li i {
  margin-right: 10px;
}

.footer .footer-col-one img {
  position: absolute;
  top: 0;
  padding: 2px;
  background-color: var(--white-color);
  height: 20%;
}

@media screen and (max-width: 769px) {
  .footer .footer-col-one img {
    position: absolute;
    top: 0;
    padding: 2px;
    background-color: var(--white-color);
    width: 30%;
    height: 15%;
  }
  #healthwaySection
    .healthwaySectionWrapper
    .leftContent
    .contentWrapper
    p
    span {
    display: inline-block;
    text-align: center;
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  #healthwaySection
    .healthwaySectionWrapper
    .rightContent
    .contentWrapper
    p
    span {
    display: inline-block;
    text-align: center;
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .sachin {
    font-size: 12px;
  }
}

@media screen and (max-width: 550px) {
  .footer .footer-col-one img {
    position: absolute;
    top: 0;
    padding: 2px;
    background-color: var(--white-color);
    width: 50%;
    height: 10%;
  }
}

.footer-col-four ul li {
  margin-right: 25px;
}

/* Plan Page css */
.plan-page {
  /* margin-top: 95px; */
  padding: 60px 0;
  background-image: url("../images/plan-bg.png");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}

.plan-page .plan-page-container h1 {
  font-size: 50px;
  font-weight: 700;
  line-height: 80px;
  margin: 0 0 20px 0;
  text-align: center;
}

.plan-page .plan-page-container p {
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  text-align: center;
}

.plan-box p {
  font-size: 18px;
  font-weight: 400;
  line-height: 36px;
  text-align: center;
}

.plan-box {
  padding: 35px;
  width: 400px;
  background: url(../images/plan1n.png);
  border-radius: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  float: right;
  margin: 60px 20px 60px 0;
  min-height: 696px;
}

.login-page-container .plan-box-2 {
  height: 100%;
  float: left;
}

.plan-box h2 {
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  line-height: 34px;
  font-family: "Rubik", sans-serif;
  color: var(--text-black-color);
  margin: 20px 0 10px 0;
}

.plan-box ul {
  padding-left: 0;
  margin: 0 0 70px 0;
}

.plan-box ul li {
  margin: 10px 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  color: var(--text-black-color);
  display: flex;
  align-items: start;
}

.plan-box ul li img {
  margin-right: 12px;
  margin-top: 5px;
}

.plan-box a {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-decoration: underline;
  text-align: center;
  margin-bottom: 20px;
  display: inline-block;
}

.plan-box-2 {
  background: url(../images/plan2new.png);
  margin-left: 20px;
  float: left;
}

.plan-box-2 ul li {
  color: var(--white-color);
}

/* Login page */
.login-page {
  /* margin-top: 95px; */
  padding: 70px 8px 120px 8px;
}

.login-page .login-page-container .plan-box {
  border-radius: 0px 10px 10px 0;
  margin: 0;
  /* padding: 0; */
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  padding-bottom: 75px;
}

.login-page .login-page-container {
  background: #f9f9f9;
  box-shadow: 0px 17px 24px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
}

.login-form-parent {
  padding: 60px 32px 0 50px;
}

.login-form-parent h1 {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: #181818;
  text-align: center;
  margin-bottom: 1rem;
}

.login-form-parent p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #181818;
  text-align: center;
}

.tab-button {
  background-color: #fcebea;
  border: 1px solid black;
  border-radius: 10px 10px 10px 10px;
}

.form-progress-bar {
  padding: 0px 30px;
  margin: 50px 0;
}

.form-progress-bar ul {
  padding: 0;
  width: 70%;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.form-progress-bar ul li {
  width: 22px;
  height: 22px;
  background: #d9d9d9;
  border-radius: 50%;
  border: 1px solid #d9d9d9;
  display: grid;
  place-items: center;
  font-size: 0.8rem;
  cursor: pointer;
  z-index: 1;
}

/* .form-progress-bar ul li::before{
    content: '\2713';
    display: inline-block;
    color: white;
    font-size: 0.85rem;
} */
.form-progress-bar ul::before,
.form-progress-bar ul .progress {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 2px;
  background: #e0e0e0;
}

.form-progress-bar ul li.active {
  background: var(--red-color);
  border-color: var(--red-color);
}

.form-progress-bar ul .progress {
  background: var(--red-color);
  transition: width 0.6s linear;
  width: calc(100% / 3 * 0);
}

.form-progress-bar ul li.active::before {
  color: var(--red-color);
  content: "\2713";
  display: inline-block;
  color: white;
  font-size: 0.85rem;
}

.otp-input {
  width: 32px;
  border: 0;
  border-bottom: 2px solid #000;
  margin: 0px 9px;
  background: transparent;
  justify-content: center;
}

.question {
  margin: 15px 0;
}

/* Radio Btn Yes No Css */
.login-page .radio-btn {
  display: none;
}

/* checkout form */

.checkout-form {
  background: #f9f9f9;
}

.quick-questions .form-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #1b1f23;
}

.member-card-wrapper .card-container {
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.03);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px;
}

.member-card-wrapper .card-container .card-left {
  display: flex;
  align-items: center;
}

.member-card-wrapper .card-container .card-left > p {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border: 1px solid #00000009;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  background: rgba(220, 51, 46, 0.1);
  color: var(--red-color);
  margin-bottom: 0;
  margin-right: 18px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.member-card-wrapper .card-container .card-right i {
  color: var(--red-color);
  font-size: 16px;
  padding: 5px;
  border: 1px solid #00000009;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: rgba(220, 51, 46, 0.1);
  cursor: pointer;
}

.member-card-wrapper .card-container .card-left .card-left-details h2 {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
}

.quick-question-btn button {
  margin: 80px 0 60px 0;
}

.mobile-carousel {
  padding: 60px auto;
}

.carousel-item {
  transition: transform 1s ease, opacity 0.5s ease-out;
  /* transition-delay: 1s; */
}

.carousel-indicators .active {
  opacity: 1 !important;
}

/* .carousel-indicators {
    margin-bottom: 10px;
} */

.carousel-indicators [data-bs-target] {
  width: 90px;
  height: 5px;
  margin: 0 8px;
  opacity: 0.2;
  /* transition-delay: 5s; */
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: var(--red-color);
}

.carousel .carousel-indicators [data-bs-target] {
  background-color: var(--red-color);
}

/***************Product Plan Page***************/

.productPlanPage {
  background-color: #f5f5f5b2;
}

.productPlanPage .productBannerSectionWrapper,
.homePage .productBannerSectionWrapper {
  padding: 35px 0;
}

.homePage .productBannerSection {
  background: #f1f1f1;
  border-radius: 20px;
  overflow: hidden;
  margin: 50px 0;
}

.productPlanPage .productBannerSection .productBannerSectionLeft,
.homePage .productBannerSection .productBannerSectionLeft {
  /* background: url("../images/young-indian-asian-family-sitting-isolated-white-background-selective-focus__3_.jpg"); */
  background-size: cover;
  background-position: top;
  height: 100%;
  overflow: hidden;
}

.homePage .productBannerSection .productBannerSectionLeft img {
  scale: 1.05;
  margin-left: -50px;
  width: 120%;
  height: -webkit-fill-available;
}

.productPlanFamilyImage img {
  /* scale: 1.7; */
}

.productPlanPage .productBannerSection .productBannerSectionRight,
.homePage .productBannerSection .productBannerSectionRight {
  padding-left: 20px;
  padding-top: 15px;
}

.productPlanPage .productBannerSection .productBannerSectionRight h2,
.homePage .productBannerSection .productBannerSectionRight h2 {
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
  color: #333333;
}

.homePage .productBannerSection .productBannerSectionRight .product-price {
  font-size: 38px;
}

.productPlanPage .productBannerSection .productBannerSectionRight p span,
.homePage .productBannerSection .productBannerSectionRight p span {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #333333;
}

.productPlanPage
  .productBannerSection
  .productBannerSectionRight
  .lists
  ul
  li
  span,
.homePage .productBannerSection .productBannerSectionRight .lists ul li span {
  padding: 10px 0;
  font-weight: 400;
  line-height: 30px;
  color: #333333;
}

.productPlanPage
  .productBannerSection
  .productBannerSectionRight
  .btnsWrapper
  .priceBtn {
  display: inline-block;
  font-weight: 600;
  font-size: 26px;
  border-radius: 0px 9px 9px 0px;
  background: rgba(220, 51, 46, 0.1);
  border-left: 8px solid var(--red-color);
  padding: 7px 0px 12px 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  width: 240px;
}

.homePage
  .productBannerSection
  .productBannerSectionRight
  .btnsWrapper
  .knowMoreBtn {
  display: inline-block;
  text-align: center;
  border-radius: 9px;
  font-weight: 500;
  font-size: 16px;
  padding: 16px 0px;
  cursor: pointer;
  outline: none;
  border: 2px solid var(--red-color);
  margin-bottom: 20px;
  margin-right: 20px;
  width: 240px;
  background: var(--white-color);
  color: var(--red-color);
  margin: 14px;
}

.homePage
  .productBannerSection
  .productBannerSectionRight
  .btnsWrapper
  .knowMoreBtn:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.productPlanPage
  .productBannerSection
  .productBannerSectionRight
  .btnsWrapper
  .buyNowBtn,
.homePage
  .productBannerSection
  .productBannerSectionRight
  .btnsWrapper
  .buyNowBtn {
  display: inline-block;
  text-align: center;
  background: #dc332e;
  border-radius: 9px;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  padding: 17px 0px;
  cursor: pointer;
  border: none;
  outline: none;
  margin-bottom: 20px;
  width: 240px;
  border: 2px solid var(--red-color);
}

.productPlanPage
  .productBannerSection
  .productBannerSectionRight
  .btnsWrapper
  .buyNowBtn,
.homePage
  .productBannerSection
  .productBannerSectionRight
  .btnsWrapper
  .buyNowBtn:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.productPlanPage .pincodeAvailibility {
  margin-top: 35px;
  margin-bottom: 20;
}

.productPlanPage .pincodeAvailibility .availibilityContainer {
  background-color: white;
}

/* .pincodeWrapper {
  margin: 30px 0 0 0;
} */

.pincodeWrapper input {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  outline: none;
  border: none;
  border-bottom: 1px solid #000;
  padding: 5px 4px;
  width: 165px;
  margin-right: 12px;
  background: none;
}

.pincodeWrapper button {
  color: white;
  background-color: var(--red-color);
  border: none;
  padding: 10px;
  border-radius: 15px;
  width: 100px;
}

.doctorConsultSection {
  background: #f1f1f1;
  padding-top: 50px;
  padding-bottom: 50px;
}

.nutritionalConsultant {
  background: white;
  padding-top: 50px;
  padding-bottom: 50px;
}

.productPlanPage .doctorConsultSection h1,
.productPlanPage .benefitsSection .container > h1,
.productPlanPage .partnerSection h1,
.productPlanPage .essentialSection h1,
.productPlanPage .reviewSection .container > h1,
.homePage .doctorConsultSection h1,
.homePage .partnerSection h1,
.homePage .workStepsSection .workStepsWrapper h1,
.homePage .reviewSection .container > h1,
.homePage .callBackSection .container > h1 {
  font-weight: 700;
  font-size: 45px;
  line-height: 68px;
  text-align: center;
  color: #333333;
}

.faqs {
  margin: 10px;
  background: #f1f1f1;
}

.faqs-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.faqs-item .accordion-item {
  background-color: white;
  width: 50%;
}

.productPlanPage .doctorConsultSection .cardsParent .cardsWrapper,
.homePage .doctorConsultSection .cardsParent .cardsWrapper {
  padding: 20px 30px 20px 30px;
  margin: 6px 6px;
  text-align: center;
  border-radius: 10.5684px;
  /* box-shadow: 5px 12px 11px rgba(0, 0, 0, 0.04); */
  min-height: 260px;
  background: linear-gradient(#f9f9f9 0%, rgba(241, 241, 241, 0) 100%);
  border-radius: 10px;
}

.productPlanPage .doctorConsultSection .cardsParent .cardsWrapper img,
.homePage .doctorConsultSection .cardsParent .cardsWrapper img {
  margin-bottom: 20px;
  margin-top: 30px;
}

.productPlanPage .doctorConsultSection .cardsParent .cardsWrapper h4,
.homePage .doctorConsultSection .cardsParent .cardsWrapper h4 {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
}

.productPlanPage .doctorConsultSection .cardsParent .cardsWrapper p,
.homePage .doctorConsultSection .cardsParent .cardsWrapper p {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
  /* padding-top: 10px; */
}

.productPlanPage .benefitsSection {
  padding: 80px 0 20px 0;
}

.productPlanPage .benefitsSection h1 > p {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
}

.productPlanPage .benefitsSection .tableParent {
  padding: 30px;
  background: #fff;
}

.productPlanPage .benefitsSection .tableParent td,
.productPlanPage .benefitsSection .tableParent th {
  padding: 5px 20px;
  width: 1200px;
}

.productPlanPage .benefitsSection .tableParent th {
  padding: 15px 20px;
}

.productPlanPage .benefitsSection .tableParent td {
  font-size: 16px;
  color: #4f4f4f;
}

.productPlanPage .benefitsSection .tableParent td ul {
  margin-bottom: 5px;
}

.productPlanPage .benefitsSection .tableParent td ul li {
  list-style: square;
  padding: 6px 0;
  /* flex-basis: 50%; */
}

.productPlanPage .benefitsSection .tableParent .table-secondary1 {
  background: rgba(249, 249, 249, 1) !important;
}

.productPlanPage .benefitsSection .tableParent tr {
  vertical-align: middle;
}

.productPlanPage .benefitsSection .btnsWrapper {
  margin: 3rem 0;
}

.productPlanPage .benefitsSection .btnsWrapper .product-btn-red {
  margin-bottom: 12px;
  margin-right: 1rem;
  width: 230px;
  padding: 18px 0;
}

.productPlanPage .benefitsSection .btnsWrapper .product-btn-white {
  margin-left: 1rem;
  margin-bottom: 12px;
  width: 230px;
  padding: 13px 0;
}

.productPlanPage .partnerSection {
  padding: 50px 0;
  background-color: white;
}

.productPlanPage .essentialSection {
  padding: 70px 30px;
  background: #f1f1f1;
  /* margin: 30px 0; */
}

.productPlanPage .essentialSection p {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #4f4f4f;
  padding: 5px 130px;
  text-align: start;
}

.productPlanPage .reviewSection {
  padding-top: 30px;
}

.productPlanPage .reviewSection h3,
.homePage .reviewSection h3 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  text-align: center;
  padding: 25px 0;
}

.productPlanPage .reviewSection .reviewTestimonial,
.homePage .reviewSection .reviewTestimonial {
  margin-top: 120px;
  margin-bottom: 80px;
  position: relative;
  display: block;
  width: 100%;
  min-height: 250px;
}

.productPlanPage .reviewSection .reviewTestimonial ul li,
.homePage .reviewSection .reviewTestimonial ul li {
  height: 400px;
  position: relative;
  background: #f1f1f1;
  border-radius: 10px;
  padding-left: 35px;
  padding-right: 35px;
  text-align: center;
}

.productPlanPage .reviewSection .reviewTestimonial ul li img,
.homePage .reviewSection .reviewTestimonial ul li img {
  height: 180px;
  width: 180px;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid var(--red-color);
}

.productPlanPage
  .reviewSection
  .reviewTestimonial
  ul
  li
  .reviewTestimonialDetail,
.homePage .reviewSection .reviewTestimonial ul li .reviewTestimonialDetail {
  position: relative;
  top: 130px;
}

.productPlanPage
  .reviewSection
  .reviewTestimonial
  ul
  li
  .reviewTestimonialDetail
  h1,
.homePage .reviewSection .reviewTestimonial ul li .reviewTestimonialDetail h1 {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #333333;
}

.productPlanPage
  .reviewSection
  .reviewTestimonial
  ul
  li
  .reviewTestimonialDetail
  p,
.homePage .reviewSection .reviewTestimonial ul li .reviewTestimonialDetail p {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #333333;
}

/* ********HOME PAGE CSS****** */

.homePage .highQualitySection {
  /* background: #F1F1F1; */
  padding: 70px 0;
}

.homePage .highQualitySection h1 {
  font-weight: 700;
  font-size: 45px;
  line-height: 68px;
}

.homePage .highQualitySection .cardsParent .cardWrapper {
  height: 225px;
  background: linear-gradient(
    324.21deg,
    #f9f9f9 10.31%,
    rgba(241, 241, 241, 0.46) 103.6%
  );
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.homePage .highQualitySection .cardsParent h4 {
  font-weight: 600;
  font-size: 40px;
  line-height: 60px;
  margin-bottom: 0;
}

.homePage .highQualitySection .cardsParent p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.homePage .customerBannerSection {
  background: url("../images/customer-bg.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  height: 650px;
  display: flex;
  align-items: center;
}

.homePage .customerBannerSection .leftContent .iconWrapper {
  margin-bottom: 25px;
}

.homePage .customerBannerSection .leftContent .iconWrapper .aiqa {
  border-right: 2px solid #333333;
}

.homePage .customerBannerSection .leftContent h3 {
  font-weight: 700;
  font-size: 30px;
  line-height: 54px;
  margin-bottom: 30px;
}

.homePage .customerBannerSection .leftContent p {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  padding-right: 110px;
}

.homePage .partnerSection {
  padding: 100px 0 220px 0;
}

.homePage .workStepsSection {
  padding: 100px 0;
}

.homePage .workStepsSection .workStepsWrapper {
  background: rgba(220, 51, 46, 0.04);
  border-radius: 20px;
  padding: 70px 40px;
}

.homePage .workStepsSection .workStepsWrapper .cardsParent .cardsWrapper {
  padding: 40px 20px 0 20px;
}

.homePage .workStepsSection .workStepsWrapper .cardsParent .cardsWrapper img {
  height: 30px;
  width: 30px;
}

.homePage
  .workStepsSection
  .workStepsWrapper
  .cardsParent
  .cardsWrapper
  .works1 {
  background: #2d9cdb;
  padding: 20px;
  border-radius: 7px;
  margin-bottom: 10px;
  display: inline-block;
}

.homePage
  .workStepsSection
  .workStepsWrapper
  .cardsParent
  .cardsWrapper
  .works2 {
  background: #27ae60;
  padding: 20px;
  border-radius: 7px;
  margin-bottom: 10px;
  display: inline-block;
}

.homePage
  .workStepsSection
  .workStepsWrapper
  .cardsParent
  .cardsWrapper
  .works3 {
  background: #eb5757;
  padding: 20px;
  border-radius: 7px;
  display: inline-block;
  margin-bottom: 10px;
}

.homePage
  .workStepsSection
  .workStepsWrapper
  .cardsParent
  .cardsWrapper
  .works4 {
  background: #bb6bd9;
  padding: 20px;
  border-radius: 7px;
  display: inline-block;
  margin-bottom: 10px;
}

.homePage .workStepsSection .workStepsWrapper .cardsParent .cardsWrapper h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}

.homePage .workStepsSection .workStepsWrapper .cardsParent .cardsWrapper p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.homePage .callBackSection {
  padding: 20px 0 20px 0;
  margin-top: 10px;
}

.homePage .callBackSection .callBackLeft {
  background: url("../images/call-back-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 100%;
  padding: 40px 38px;
  color: #fff;
}

.homePage .callBackSection .callBackLeft h6 {
  margin-bottom: 28px;
}

.homePage .callBackSection .callBackLeft p {
  margin-bottom: 20px;
  line-height: 28px;
}

.homePage .callBackSection .callBackLeft ul li {
  padding: 10px 0;
}

.homePage .callBackSection .callBackRight {
  padding: 60px 50px;
}

.homePage .callBackSection .callBackRight .form-control {
  outline: none;
  border: none;
  border-bottom: 1px solid #000;
  border-radius: 0;
  margin-bottom: 50px;
  width: 95%;
}

.homePage .callBackSection .callBackRight .form-control:focus {
  background-color: none;
  box-shadow: none;
  border-bottom: 1px solid var(--red-color);
}

.homePage .callBackSection .callBackRight button:hover {
  color: #fff;
}

.homePage .essentialProSection {
  /* background:url("../images/healthway-essential-pro.png"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  margin-top: 100px;
  margin-bottom: 100px;
}

.homePage .downloadSection {
  background: url("../images/download-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.homePage .downloadSection .leftContent {
  position: absolute;
  bottom: 0;
}

.homePage .downloadSection .rightContent {
  padding: 120px 0;
}

.homePage .downloadSection .rightContent h3 {
  font-weight: 700;
  font-size: 56px;
  line-height: 84px;
}

.homePage .downloadSection .rightContent p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 40px;
}

/* .homePage .homeSliderSection .homeSliderWrapper1 {
  background: url("../images/banner1new.png");
  height: 320px;
  margin-left: 5px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
} */

/* .homePage .homeSliderSection .homeSliderWrapper2 {
  background: url("../images/banner2new.png");
  height: 320px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
} */

/* .homePage .homeSliderSection .homeSliderWrapper2 .contentWrapper {
    background: url('../images/banner-img-2-inner.png');
    height: 400px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 95%;
    position: relative;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding-left: 100px;
} */

/* .homePage .homeSliderSection .homeSliderWrapper2 .contentWrapper h2 {
    font-weight: 800;
    font-size: 40px;
    line-height: 60px;
}

.homePage .homeSliderSection .homeSliderWrapper1 .contentWrapper h1 {
    font-weight: 800;
    font-size: 54px;
    line-height: 85px;
    padding-left: 100px;
}

.homePage .homeSliderSection .homeSliderWrapper1 .contentWrapper p {
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    margin: 48px 0;
    background-color: rgba(220, 51, 46, 0.1);
    padding: 14px 14px 14px 100px;
    display: inline-block;
} */

.homePage .homeSliderSection .homeSliderWrapper1 .contentWrapper .red-btn {
  border-radius: 9px;
  width: 230px;
  padding: 17px 0;
  margin-left: 100px;
  display: block;
  margin-top: 460px;
  background-color: #000;
}

.homePage .homeSliderSection .homeSliderWrapper2 .contentWrapper .red-btn {
  border-radius: 9px;
  width: 230px;
  padding: 17px 0;
  margin-left: 100px;
  display: block;
  margin-top: 460px;
  background-color: #000;
}

/* ********Payment form card****** */
.paymentFormCard .editBtn {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  padding: 6px 23px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  border: 0;
  color: #fff;
}

.paymentFormCard .form-label {
  color: #fff;
}

.paymentFormCard .form-control:focus {
  outline: 0;
  box-shadow: none;
}

.paymentFormCard ::placeholder {
  color: #fff;
}

/* .paymentFormCard .radio-btn-label-2{
    color: #fff;
    border: 1px solid #fff;
    background: transparent;
} */
/* .paymentFormCard #inlineCheckbox11:checked ~ .radio-btn-label-2,
.paymentFormCard #inlineCheckbox22:checked ~ .radio-btn-label-2,
.paymentFormCard #inlineCheckbox33:checked ~ .radio-btn-label-2{
    border: 1px solid #fff;
    color: #667085;
    background: #fff;

} */
/* .paymentFormCard input[type="date"]::-webkit-calendar-picker-indicator { */
/* display: none; */
/* -webkit-appearance: none; */
/* } */

.paymentTableWrapper {
  width: 80%;
  margin: 20px auto;
}

.paymentTableWrapper .table {
  background: #efefef;
  padding: 6px 12px;
  display: inline-block;
}

.paymentTableWrapper th,
.paymentTableWrapper td {
  width: 2000px;
  padding: 11px 24px;
  background: #efefef;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.paymentTableWrapper .paymentWrapper .paymentCard {
  background: #ffffff;
  box-shadow: 0px 14px 16px rgba(24, 59, 128, 0.02);
  border-radius: 10px;
  text-align: center;
  padding: 25px 0;
  margin: 30px 0;
}

.paymentTableWrapper .paymentWrapper .form-check {
  width: 50%;
  margin-bottom: 10px;
}

.paymentTableWrapper .paymentWrapper .form-check .radio-btn-label {
  background: #ffffff;
  box-shadow: 0px 14px 16px rgb(24 59 128 / 2%);
  border-radius: 10px !important;
  text-align: center;
  padding: 25px 0 !important;
  width: 100%;
}

.paymentTableWrapper
  .paymentWrapper
  #inlinePaymentCheckbox1:checked
  ~ .radio-btn-label,
.paymentTableWrapper
  .paymentWrapper
  #inlinePaymentCheckbox2:checked
  ~ .radio-btn-label {
  border: 2px solid var(--red-color);
}

/* *************Payment Successful Page************ */

.paymentSuccessfulWrapper {
  background: url("../images/payment-successful-bg.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  max-width: 600px;
  margin: auto;
  text-align: center;
}

.paymentSuccessfulWrapper .paymentSuccessfulImage {
  margin: 120px 0 30px 0;
}

.paymentSuccessfulWrapper .paymentSuccessfulDetails h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}

.paymentSuccessfulWrapper .paymentSuccessfulDetails p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.checkout-form-btn {
  background: #fcebea;
  height: 50px;
}

/* ***********Refferal Code Popup*********** */

.refferalCodeModal .modal-lg {
  max-width: 622px;
}

.refferalCodeModal .modal-content {
  height: 500px;
  background: #f1f1f1;
  border-radius: 0;
  border: none;
}

.refferalCodeModal .refferalWrapper {
  margin: 20px 0;
}

.refferalCodeModal .refferalCodeWrapper {
  border: 2px solid #4f4f4f93;
  border-right: 0;
  border-left: 0;
  padding: 20px 0;
}

.refferalCodeModal .refferalCodeWrapper .refferalCodeLeft {
  text-align: left;
}

.refferalCodeModal .refferalCodeWrapper .refferalCodeRight {
  text-align: right;
}

.payment-success-container {
  background: #f9f9f9;
  box-shadow: 0px 17px 24px rgb(0 0 0 / 7%);
  border-radius: 10px;
  padding-bottom: 100px;
}

.otp-input input {
  width: 100%;
  text-align: center;
  border: none;
  outline: none;
  background: none;
}

.final-pay-btns .white-btn {
  margin-right: 20px;
}

.final-pay-btns .red-btn {
  margin-left: 20px;
}

.carousel-item {
  transition: transform 1s ease, opacity 0.5s ease-out;
  /* transition-delay: 5s; */
}

.carousel-indicators .active {
  opacity: 1 !important;
}

.carousel-indicators [data-bs-target] {
  width: 90px;
  height: 5px;
  margin: 0 8px;
  opacity: 0.2;
  /* transition-delay: 5s; */
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: var(--red-color);
}

.labTestHeading {
  margin-bottom: 3rem;
}

.cardsWrapperPara {
  margin: 2px;
  margin-bottom: 120px;
  background-color: white;
  height: 80px;
  font-size: 10px;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  box-shadow: #fcebea 0px 8px 24px;
  /* box-shadow: #DC332E 0px 4px 16px, #DC332E 0px 8px 32px; */
  border-radius: 5px;
}

.cardsWrapperPara div {
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardsWrapperPara div p {
  font-size: 18px;
  display: contents;
  /* font-weight: 500; */
}

.cardsWrapperPara .class1 {
  border-right: 1px solid #dc332e;
}

@media screen and (min-width: 767px) {
  .cardsWrapperPara .class2 {
    border-right: 1px solid #dc332e;
  }
}

.cardsWrapperPara .class3 {
  border-right: 1px solid #dc332e;
}

/************ Responsive design ***************/

@media screen and (max-width: 992px) {
  .topbar {
    display: none;
  }

  .footer {
    padding: 106px 12px 60px 12px;
  }

  .login-form-parent {
    padding: 60px 25px 60px 25px;
  }

  .form-field {
    margin-bottom: 25px;
  }

  .question {
    align-items: start !important;
    flex-direction: column;
  }

  .question .form-label {
    margin-bottom: 15px;
  }

  .quick-question-btn button {
    margin: 25px 0 10px 0;
  }

  .plan-page .plan-page-container .plan-box {
    float: none;
    margin-left: auto;
    margin-right: auto;
    min-height: 50px;
  }

  .plan-page .plan-page-container .plan-box-2 {
    float: none;
    margin-left: auto;
    margin-right: auto;
    min-height: 50px;
  }

  .plan-box-btn {
    margin-bottom: 25px;
  }

  .payment-form-card-mobile .paymentFormCard {
    border-radius: 10px 10px 10px 10px !important;
  }

  .productPlanPage .productBannerSection .productBannerSectionLeft {
    /* background: url("../images/young-indian-asian-family-sitting-isolated-white-background-selective-focus__3_.jpg"); */
    background-size: cover;
    background-position: top;
    height: 450px;
  }

  .productPlanPage .essentialSection p {
    padding: 5px 10px;
  }

  .fs-md-10 {
    font-size: 10px;
  }

  .fs-md-11 {
    font-size: 11px;
  }

  .fs-md-12 {
    font-size: 12px;
  }

  .fs-md-13 {
    font-size: 13px;
  }

  .fs-md-14 {
    font-size: 0.88rem;
  }

  .fs-md-15 {
    font-size: 0.94rem;
  }

  .fs-md-16 {
    font-size: 1rem;
  }

  .fs-md-17 {
    font-size: 17px;
  }

  .fs-md-18 {
    font-size: 18px;
  }

  .fs-md-19 {
    font-size: 19px;
  }

  .fs-md-20 {
    font-size: 20px;
  }

  .fs-md-22 {
    font-size: 22px;
  }

  .fs-md-24 {
    font-size: 24px;
  }

  .fs-md-26 {
    font-size: 26px;
  }

  .fs-md-28 {
    font-size: 28px;
  }

  .fs-md-30 {
    font-size: 30px;
  }

  /* ===============FONT WEIGHTS================== */
  .fw-md-2 {
    font-weight: 200;
  }

  .fw-md-3 {
    font-weight: 300;
  }

  .fw-md-4 {
    font-weight: 400;
  }

  .fw-md-5 {
    font-weight: 500;
  }

  .fw-md-6 {
    font-weight: 600;
  }

  .fw-md-7 {
    font-weight: 700;
  }

  .fw-md-8 {
    font-weight: 800;
  }

  .productPlanPage .doctorConsultSection .cardsParent .cardsWrapper,
  .homePage .doctorConsultSection .cardsParent .cardsWrapper {
    padding: 20px 10px 20px 10px;
    min-height: 250px;
    background: linear-gradient(#f9f9f9 0%, rgba(241, 241, 241, 0) 100%);
    border-radius: 10px;
    /* border: 2px solid rgba(220, 51, 46, 0.2); */
    margin: 12px 0;
  }

  .productPlanPage .doctorConsultSection .cardsParent .cardsWrapper img,
  .homePage .doctorConsultSection .cardsParent .cardsWrapper img {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .productPlanPage .doctorConsultSection .cardsParent .cardsWrapper h4,
  .homePage .doctorConsultSection .cardsParent .cardsWrapper h4 {
    font-size: 17px;
    line-height: 23px;
  }

  .productPlanPage .doctorConsultSection .cardsParent .cardsWrapper p,
  .homePage .doctorConsultSection .cardsParent .cardsWrapper p {
    font-size: 13px;
    line-height: 18px;
  }

  .productPlanPage
    .reviewSection
    .reviewTestimonial
    ul
    li
    .reviewTestimonialDetail
    h1,
  .homePage
    .reviewSection
    .reviewTestimonial
    ul
    li
    .reviewTestimonialDetail
    h1 {
    font-size: 18px;
    line-height: 27px;
  }

  .productPlanPage
    .reviewSection
    .reviewTestimonial
    ul
    li
    .reviewTestimonialDetail
    p,
  .homePage .reviewSection .reviewTestimonial ul li .reviewTestimonialDetail p {
    font-size: 14px;
  }

  .productPlanPage .reviewSection .reviewTestimonial ul li,
  .homePage .reviewSection .reviewTestimonial ul li {
    height: 480px;
  }

  .homePage .highQualitySection .cardsParent .cardWrapper {
    height: 225px;
  }

  .homePage .highQualitySection .cardsParent h4 {
    font-size: 30px;
    line-height: 40px;
  }

  .homePage .highQualitySection .cardsParent p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }

  .homePage .customerBannerSection {
    background-position: center;
  }

  .homePage .customerBannerSection .leftContent h3 {
    line-height: 48px;
    margin-bottom: 30px;
  }

  .homePage .customerBannerSection .leftContent p {
    line-height: 28px;
    padding-right: 50px;
  }

  .homePage .partnerSection {
    padding: 100px 0 150px 0;
  }

  .homePage .partnerSection img {
    width: 100%;
  }

  .homePage .downloadSection .leftContent {
    position: absolute;
  }

  .homePage .downloadSection .leftContent img {
    position: relative;
    bottom: 0;
    width: 75%;
  }

  .homePage .downloadSection .rightContent {
    padding: 50px 0;
  }

  .homePage .downloadSection .rightContent h3 {
    font-weight: 700;
    font-size: 42px;
    line-height: 58px;
  }

  .homePage .downloadSection .rightContent p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 40px;
  }

  .homePage .workStepsSection .workStepsWrapper {
    padding: 70px 20px;
  }

  .homePage .workStepsSection .workStepsWrapper .cardsParent .cardsWrapper {
    padding: 40px 10px 0 10px;
  }

  .homePage
    .workStepsSection
    .workStepsWrapper
    .cardsParent
    .cardsWrapper
    .works1 {
    padding: 10px;
  }

  .homePage
    .workStepsSection
    .workStepsWrapper
    .cardsParent
    .cardsWrapper
    .works2 {
    padding: 10px;
  }

  .homePage
    .workStepsSection
    .workStepsWrapper
    .cardsParent
    .cardsWrapper
    .works3 {
    padding: 10px;
  }

  .homePage
    .workStepsSection
    .workStepsWrapper
    .cardsParent
    .cardsWrapper
    .works4 {
    padding: 10px;
  }

  .homePage .workStepsSection .workStepsWrapper .cardsParent .cardsWrapper h4 {
    font-size: 17px;
  }

  .homePage .workStepsSection .workStepsWrapper .cardsParent .cardsWrapper p {
    font-size: 13px;
  }

  .homePage .callBackSection {
    padding: 20px 0 100px 0;
  }

  .homePage .callBackSection .callBackLeft {
    padding: 40px 25px;
  }

  .homePage .callBackSection .callBackLeft h6 {
    margin-bottom: 28px;
  }

  .homePage .callBackSection .callBackLeft p {
    margin-bottom: 20px;
    line-height: 28px;
  }

  .homePage .callBackSection .callBackLeft ul li {
    padding: 10px 0;
  }

  .homePage .callBackSection .callBackRight {
    padding: 75px 10px;
  }

  .homePage .callBackSection .callBackRight .form-control {
    outline: none;
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 0;
    margin-bottom: 40px;
    width: 95%;
  }

  .homePage .homeSliderSection .homeSliderWrapper1,
  .homePage .homeSliderSection .homeSliderWrapper2 {
    height: 400px;
  }

  .contentWrapper h1 {
    font-weight: 700;
    font-size: 45px;
    line-height: 56px;
    padding-left: 70px;
  }

  .homePage .homeSliderSection .homeSliderWrapper1 .contentWrapper p {
    font-weight: 500;
    font-size: 26px;
    line-height: 40px;
    margin: 30px 0;
    padding: 14px 14px 14px 70px;
  }

  .homePage .homeSliderSection .homeSliderWrapper1 .contentWrapper .red-btn {
    border-radius: 9px;
    width: 230px;
    padding: 17px 0;
    margin-left: 70px;
    margin-top: 280px;
  }

  .homePage .homeSliderSection .homeSliderWrapper2 .contentWrapper .red-btn {
    border-radius: 9px;
    width: 230px;
    padding: 17px 0;
    margin-left: 40px;
    margin-top: 550px;
  }

  .homePage .homeSliderSection .homeSliderWrapper2 .contentWrapper {
    width: 100%;
    padding-left: 50px;
    height: 300px;
  }

  .homePage .homeSliderSection .homeSliderWrapper2 .contentWrapper h2 {
    font-weight: 800;
    font-size: 30px;
    line-height: 40px;
  }
}

@media screen and (max-width: 769px) {
  .productPlanPage
    .productBannerSection
    .productBannerSectionRight
    .btnsWrapper
    .priceBtn {
    width: 220px;
  }

  .productPlanPage
    .productBannerSection
    .productBannerSectionRight
    .btnsWrapper
    .buyNowBtn {
    width: 220px;
  }

  .productPlanPage .doctorConsultSection h1,
  .productPlanPage .benefitsSection .container > h1,
  .productPlanPage .partnerSection h1,
  .productPlanPage .essentialSection h1,
  .productPlanPage .reviewSection .container > h1,
  .homePage .doctorConsultSection h1,
  .homePage .partnerSection h1,
  .homePage .workStepsSection .workStepsWrapper h1,
  .homePage .reviewSection .container > h1,
  .homePage .callBackSection .container > h1 {
    font-size: 35px;
    line-height: 50px;
  }

  .productPlanPage .essentialSection {
    padding: 10px 5px;
    background: #f1f1f1;
    margin: 30px 0;
  }

  .fs-sm-10 {
    font-size: 10px;
  }

  .fs-sm-11 {
    font-size: 11px;
  }

  .fs-sm-12 {
    font-size: 12px;
  }

  .fs-sm-13 {
    font-size: 13px;
  }

  .fs-sm-14 {
    font-size: 0.88rem;
  }

  .fs-sm-15 {
    font-size: 0.94rem;
  }

  .fs-sm-16 {
    font-size: 1rem;
  }

  .fs-sm-17 {
    font-size: 17px;
  }

  .fs-sm-18 {
    font-size: 18px;
  }

  .fs-sm-19 {
    font-size: 19px;
  }

  .fs-sm-20 {
    font-size: 20px;
  }

  .fs-sm-22 {
    font-size: 22px;
  }

  .fs-sm-24 {
    font-size: 24px;
  }

  .fs-sm-26 {
    font-size: 26px;
  }

  .fs-sm-28 {
    font-size: 28px;
  }

  .fs-sm-30 {
    font-size: 30px;
  }

  /* ===============FONT WEIGHTS================== */
  .fw-sm-2 {
    font-weight: 200;
  }

  .fw-sm-3 {
    font-weight: 300;
  }

  .fw-sm-4 {
    font-weight: 400;
  }

  .fw-sm-5 {
    font-weight: 500;
  }

  .fw-sm-6 {
    font-weight: 600;
  }

  .fw-sm-7 {
    font-weight: 700;
  }

  .fw-sm-8 {
    font-weight: 800;
  }

  .productPlanPage .doctorConsultSection .cardsParent .cardsWrapper {
    padding: 20px 30px 20px 30px;
    min-height: 250px;
    background: linear-gradient(0deg, #f9f9f9 0%, rgba(241, 241, 241, 0) 100%);
    border-radius: 10px;
    border: 2px solid rgba(220, 51, 46, 0.2);
  }

  .homePage .doctorConsultSection .cardsParent .cardsWrapper {
    padding: 20px 10px 20px 10px;
    min-height: 175px;
    background: linear-gradient(#f9f9f9 0%, rgba(241, 241, 241, 0) 100%);
    border-radius: 10px;
    border: 2px solid rgba(220, 51, 46, 0.2);
  }

  .productPlanPage .doctorConsultSection .cardsParent .cardsWrapper img,
  .homePage .doctorConsultSection .cardsParent .cardsWrapper img {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .productPlanPage .doctorConsultSection .cardsParent .cardsWrapper h4,
  .homePage .doctorConsultSection .cardsParent .cardsWrapper h4 {
    font-size: 16px;
    line-height: 28px;
  }

  .productPlanPage .doctorConsultSection .cardsParent .cardsWrapper p,
  .homePage .doctorConsultSection .cardsParent .cardsWrapper p {
    font-size: 12px;
    line-height: 19px;
  }

  .productPlanPage .reviewSection,
  .homePage .reviewSection {
    padding-bottom: 70px;
  }

  .plan-page .plan-page-container p {
    font-size: 15px;
  }

  .homePage .highQualitySection {
    padding: 50px 0;
  }

  .homePage .highQualitySection h1 {
    font-size: 35px;
    line-height: 50px;
  }

  .homePage .highQualitySection .cardsParent .cardWrapper {
    margin-bottom: 25px;
  }

  .homePage .partnerSection {
    padding: 50px 0 150px 0;
  }

  .homePage .partnerSection img {
    width: 100%;
  }

  .homePage .homeSliderSection .homeSliderWrapper1,
  .homePage .homeSliderSection .homeSliderWrapper2 {
    height: 300px;
  }

  .homePage .homeSliderSection .homeSliderWrapper1 .contentWrapper h1 {
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    padding-left: 40px;
    margin-bottom: 0;
  }

  .homePage .homeSliderSection .homeSliderWrapper1 .contentWrapper p {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    margin: 20px 0;
    padding: 10px 10px 10px 40px;
  }

  .homePage .homeSliderSection .homeSliderWrapper1 .contentWrapper .red-btn {
    border-radius: 9px;
    width: 230px;
    padding: 14px 0;
    margin-left: 40px;
    margin-bottom: 60px;
  }

  .homePage .homeSliderSection .homeSliderWrapper2 .contentWrapper .red-btn {
    border-radius: 9px;
    width: 230px;
    padding: 14px 0;
    margin-left: 40px;
    margin-top: 350px;
  }

  .homePage .homeSliderSection .homeSliderWrapper2 .contentWrapper {
    padding-left: 50px;
    height: 200px;
  }

  .homePage .homeSliderSection .homeSliderWrapper2 .contentWrapper h2 {
    font-weight: 800;
    font-size: 30px;
    line-height: 40px;
  }

  .productPlanPage .productBannerSection .productBannerSectionRight h2,
  .homePage .productBannerSection .productBannerSectionRight h2 {
    font-weight: 700;
    font-size: 35px;
    line-height: 60px;
    color: #333333;
  }

  .faqs-item .accordion-item {
    width: 100%;
  }

  .cardsWrapperPara {
    margin: 2px;
    margin-bottom: 80px;
    background-color: white;
    height: 80px;
    font-size: 10px;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    box-shadow: #fcebea 0px 8px 24px;
    /* box-shadow: #DC332E 0px 4px 16px, #DC332E 0px 8px 32px; */
    border-radius: 5px;
  }

  .cardsWrapperPara div {
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cardsWrapperPara .class1 {
    border-right: 1px solid #dc332e;
  }

  .cardsWrapperPara .class3 {
    border-right: 1px solid #dc332e;
  }
}

@media screen and (max-width: 767px) {
  .homePage .downloadSection .leftContent {
    position: relative;
    text-align: center;
  }

  .homePage .downloadSection .leftContent img {
    position: relative;
    bottom: 0;
    width: 90%;
  }

  .homePage .downloadSection .rightContent {
    padding: 50px 0;
  }

  .homePage .downloadSection .rightContent h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 51px;
  }

  .homePage .downloadSection .rightContent p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 40px;
  }

  .homePage .downloadSection .rightContent .iconWrapper img {
    width: 140px;
  }

  .homePage .workStepsSection {
    padding: 0px 0;
    background: rgba(220, 51, 46, 0.04);
  }

  .homePage .workStepsSection .workStepsWrapper {
    background: none;
    border-radius: 0px;
    padding: 70px 10px;
  }

  .homePage .workStepsSection .workStepsWrapper .cardsParent .cardsWrapper {
    padding: 20px 0px 0 0px;
  }

  .homePage .workStepsSection .workStepsWrapper .cardsParent .cardsWrapper h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .homePage .workStepsSection .workStepsWrapper .cardsParent .cardsWrapper p {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
  }

  .homePage .reviewSection {
    margin-top: 60px;
  }

  .homePage .callBackSection {
    padding: 10px 0 30px 0;
  }

  .homePage .callBackSection .callBackLeft {
    padding: 40px 25px;
    text-align: center;
  }

  .homePage .callBackSection .callBackLeft ul li {
    padding: 10px 0;
    text-align: left;
  }

  .homePage .callBackSection .callBackRight {
    padding: 35px 10px;
  }

  .homePage .callBackSection .callBackRight .form-control {
    margin-bottom: 20px;
    width: 100%;
  }

  .homePage .homeSliderSection .homeSliderWrapper2 .contentWrapper {
    padding-left: 10px;
    height: 170px;
  }

  .homePage .homeSliderSection .homeSliderWrapper2 .contentWrapper h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
  }

  .homePage .productBannerSectionWrapper {
    padding: 20px 0;
  }

  .productPlanPage .productBannerSection .productBannerSectionRight,
  .homePage .productBannerSection .productBannerSectionRight {
    text-align: center;
  }

  .knowMoreButton {
    margin-left: 0 !important;
  }

  .productPlanPage .productBannerSection .productBannerSectionRight h2,
  .homePage .productBannerSection .productBannerSectionRight h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 60px;
    color: #333333;
  }

  .productPlanPage
    .productBannerSection
    .productBannerSectionRight
    .lists
    ul
    li
    span,
  .homePage .productBannerSection .productBannerSectionRight .lists ul li span {
    padding: 10px 0;
    font-weight: 400;
    line-height: 30px;
    color: #333333;
    font-size: 14px;
  }

  .marquee {
    height: 25px;
    width: 920px;

    overflow: hidden;
    position: relative;
  }

  .labTestHeading {
    margin-bottom: 2rem;
  }

  .cardsWrapperPara {
    margin: 2px;
    margin-bottom: 80px;
    background-color: white;
    height: 100px;
    font-size: 10px;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    box-shadow: #fcebea 0px 8px 24px;
    border-radius: 5px;
  }

  .cardsWrapperPara div {
    height: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cardsWrapperPara .class1 {
    border-right: 1px solid #dc332e;
  }

  .cardsWrapperPara .class3 {
    border-right: 1px solid #dc332e;
  }
}

@media screen and (max-width: 550px) {
  .fs-esm-10 {
    font-size: 10px;
  }

  .fs-esm-11 {
    font-size: 11px;
  }

  .fs-esm-12 {
    font-size: 12px;
  }

  .fs-esm-13 {
    font-size: 13px;
  }

  .fs-esm-14 {
    font-size: 0.88rem;
  }

  .fs-esm-15 {
    font-size: 0.94rem;
  }

  .fs-esm-16 {
    font-size: 1rem;
  }

  .fs-esm-17 {
    font-size: 17px;
  }

  .fs-esm-18 {
    font-size: 18px;
  }

  .fs-esm-19 {
    font-size: 19px;
  }

  .fs-esm-20 {
    font-size: 20px;
  }

  .fs-esm-22 {
    font-size: 22px;
  }

  .fs-esm-24 {
    font-size: 24px;
  }

  .fs-esm-26 {
    font-size: 26px;
  }

  .fs-esm-28 {
    font-size: 28px;
  }

  .fs-esm-30 {
    font-size: 30px;
  }

  /* ===============FONT WEIGHTS================== */
  .fw-esm-2 {
    font-weight: 200;
  }

  .fw-esm-3 {
    font-weight: 300;
  }

  .fw-esm-4 {
    font-weight: 400;
  }

  .fw-esm-5 {
    font-weight: 500;
  }

  .fw-esm-6 {
    font-weight: 600;
  }

  .fw-esm-7 {
    font-weight: 700;
  }

  .fw-esm-8 {
    font-weight: 800;
  }

  .final-pay-btns .white-btn {
    margin-right: 0px;
    margin-bottom: 30px;
  }

  .final-pay-btns .red-btn {
    margin-left: 0px;
  }

  .productPlanPage .benefitsSection .btnsWrapper {
    margin: 1rem 0;
  }

  .productPlanPage .benefitsSection .btnsWrapper .product-btn-red {
    margin-right: 5px;
    margin-left: 5px;
  }

  .productPlanPage .benefitsSection .btnsWrapper .product-btn-white {
    margin-right: 5px;
    margin-left: 5px;
  }

  .productPlanPage .benefitsSection .tableParent {
    padding: 10px;
  }

  .productPlanPage .benefitsSection .tableParent td,
  .productPlanPage .benefitsSection .tableParent th {
    padding: 3px 10px;
    width: 1200px;
  }

  .productPlanPage .benefitsSection .tableParent th {
    padding: 8px 10px;
  }

  .productPlanPage .benefitsSection .tableParent td {
    font-size: 14px;
  }

  .productPlanPage .benefitsSection .tableParent td ul li {
    list-style: square;
    padding: 6px 0;
    font-size: 14px;
  }

  .productPlanPage .doctorConsultSection .cardsParent .cardsWrapper {
    padding: 20px 20px 20px 20px;
    min-height: 150px;
    width: 100%;
  }

  .productPlanPage .doctorConsultSection h1,
  .productPlanPage .benefitsSection .container > h1,
  .productPlanPage .partnerSection h1,
  .productPlanPage .essentialSection h1,
  .productPlanPage .reviewSection .container > h1,
  .homePage .doctorConsultSection h1,
  .homePage .partnerSection h1,
  .homePage .workStepsSection .workStepsWrapper h1,
  .homePage .reviewSection .container > h1,
  .homePage .callBackSection .container > h1 {
    font-size: 24px;
    line-height: 50px;
  }

  .homePage .doctorConsultSection .cardsParent .cardsWrapper {
    min-height: 200px;
  }

  .productPlanPage .partnerSection {
    padding: 50px 0;
  }

  .productPlanPage .essentialSection {
    padding: 20px 10px;
    background: #f1f1f1;
  }

  .productPlanPage .essentialSection p {
    font-size: 14px;
    line-height: 28px;
    text-align: justify;
  }

  .productPlanPage .productBannerSection .productBannerSectionRight h2 {
    font-size: 30px;
    line-height: 45px;
  }

  .homePage .productBannerSection .productBannerSectionRight .product-price {
    font-size: 32px;
  }

  .productPlanPage .doctorConsultSection .cardsParent .cardsWrapper {
    padding: 20px 25px 20px 25px;
    text-align: center;
    min-height: 230px;
    background: linear-gradient(0deg, #f9f9f9 0%, rgba(241, 241, 241, 0) 100%);
    border-radius: 10px;
    border: 2px solid rgba(220, 51, 46, 0.2);
  }

  .productPlanPage .doctorConsultSection .cardsParent .cardsWrapper img {
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .productPlanPage .doctorConsultSection .cardsParent .cardsWrapper h4 {
    font-size: 14px;
    line-height: 20px;
  }

  .productPlanPage .doctorConsultSection .cardsParent .cardsWrapper p {
    font-size: 10px;
    line-height: 14px;
  }

  .productPlanPage .productBannerSection .productBannerSectionRight {
    background: #f1f1f1;
    box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    margin-top: -20px;
    padding: 40px 10px 40px 10px;
  }

  .productPlanPage .productBannerSection .productBannerSectionLeft {
    height: 270px;
  }

  .paymentTableWrapper {
    width: 100%;
    margin: 20px auto;
  }

  .paymentTableWrapper th,
  .paymentTableWrapper td {
    width: 2000px;
    padding: 8px 10px;
    font-size: 15px;
    line-height: 23px;
  }

  .login-form-parent {
    padding: 60px 15px 60px 15px;
  }

  .homePage .highQualitySection h1 {
    font-size: 24px;
    line-height: 50px;
  }

  .homePage .highQualitySection .cardsParent .cardWrapper {
    height: 160px;
  }

  .homePage .highQualitySection .cardsParent h4 {
    font-size: 28px;
    line-height: 41px;
  }

  .homePage .highQualitySection .cardsParent p {
    font-size: 14px;
    line-height: 14px;
  }

  .homePage .customerBannerSection {
    height: 560px;
  }

  .homePage .customerBannerSection .leftContent h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
  }

  .homePage .customerBannerSection .leftContent p {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
  }

  .homePage .partnerSection {
    padding: 50px 0 50px 0;
  }

  /* .homePage .homeSliderSection .homeSliderWrapper1 {
    background-image: url("../images/banner1newmobile.png");
    height: 195px;
    width: 96%;
    margin-left: 10px;
  }
  .homePage .homeSliderSection .homeSliderWrapper2 {
    background-image: url("../images/banner2newmobile.png");
    height: 195px;
    
    width: 96%;
    margin-left: 10px;
  } */

  .homePage .homeSliderSection .homeSliderWrapper1 .contentWrapper h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 31px;
    padding-left: 10px;
    margin-bottom: 0;
  }

  .homePage .homeSliderSection .homeSliderWrapper1 .contentWrapper p {
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    margin: 10px 0;
    padding: 5px 5px 5px 10px;
  }

  .homePage .homeSliderSection .homeSliderWrapper1 .contentWrapper .red-btn {
    border-radius: 9px;
    width: 150px;
    padding: 6px 0;
    margin-left: 25px;
    margin-top: 200px;
  }

  .homePage .homeSliderSection .homeSliderWrapper2 .contentWrapper .red-btn {
    border-radius: 9px;
    width: 150px;
    padding: 6px 0;
    margin-left: 15px;
    margin-top: 290px;
  }

  .homePage .homeSliderSection .homeSliderWrapper2 .contentWrapper {
    padding-left: 10px;
    height: 170px;
  }

  .homePage .homeSliderSection .homeSliderWrapper2 .contentWrapper h2 {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  }

  .productPlanPage .productBannerSection .productBannerSectionRight h2,
  .homePage .productBannerSection .productBannerSectionRight h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 60px;
    color: #333333;
  }

  .labTestHeading {
    margin-bottom: 1.5rem;
  }

  .cardsWrapperPara {
    margin: 2px;
    margin-bottom: 80px;
    background-color: white;
    height: 100px;
    font-size: 10px;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    box-shadow: #fcebea 0px 8px 24px;
    border-radius: 5px;
  }

  .cardsWrapperPara div {
    height: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cardsWrapperPara .class1 {
    border-right: 1px solid #dc332e;
  }

  .cardsWrapperPara .class3 {
    border-right: 1px solid #dc332e;
  }

  .homePage .essentialProSection img {
    height: 100px;
  }

  .homePage .productBannerSection .productBannerSectionLeft img {
    scale: 1;
    margin-left: 50px;
    width: 300px;
    height: 200px;
  }

  .knowMoreButton {
    border-radius: 15px;
    /* padding: 1px; */
    font-size: 14px;
    /* border: 1px solid var(--red-color); */
    /* color: var(--red-color); */
    border: none;
    text-decoration: underline;
    margin-left: 0px !important;
  }
}

@media screen and (max-width: 450px) {
  .plan-box {
    padding: 20px;
    width: 100%;
  }

  .plan-box a {
    display: block;
  }

  .plan-page .plan-page-container h1 {
    font-size: 40px;
  }

  .plan-page .plan-page-container > p {
    font-size: 14px;
  }

  .productPlanPage .reviewSection .reviewTestimonial ul li,
  .homePage .reviewSection .reviewTestimonial ul li {
    height: 480px;
    padding: 10px;
  }

  .productPlanPage
    .productBannerSection
    .productBannerSectionRight
    .btnsWrapper
    .priceBtn {
    width: 280px;
  }

  .productPlanPage
    .productBannerSection
    .productBannerSectionRight
    .btnsWrapper
    .buyNowBtn {
    width: 280px;
  }

  .refferalCodeModal .refferalCodeWrapper .refferalCodeRight {
    text-align: left;
    margin-top: 15px;
  }

  .productPlanPage .benefitsSection .tableParent td,
  .productPlanPage .benefitsSection .tableParent th {
    padding: 3px 3px;
  }

  .productPlanPage .benefitsSection .tableParent th {
    padding: 8px 4px;
  }

  .paymentTableWrapper .paymentWrapper .form-check .radio-btn-label img {
    width: 90px;
  }

  /* .homePage .homeSliderSection .homeSliderWrapper1 {
             background-image: url('../images/banner1mobile.png');
             height: 200px;
    
         }
    
    .homePage .homeSliderSection .homeSliderWrapper2 {
             background-image: url('../images/banner2mobile.png');
             height: 200px;
    
         } */

  .cardsWrapperPara {
    margin: 2px;
    margin-bottom: 80px;
    background-color: white;
    height: 150px;
    font-size: 10px;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    box-shadow: #fcebea 0px 8px 24px;
    border-radius: 5px;
  }

  .cardsWrapperPara div {
    height: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cardsWrapperPara .class1 {
    border-right: 1px solid #dc332e;
  }

  .cardsWrapperPara .class3 {
    border-right: 1px solid #dc332e;
  }
}

.accordion-item .accordion-button:focus {
  color: var(--black-color);
  text-decoration: none;
  transition: all 0.3s;
}

.acco-item-one {
  border: 1px sold red;
}

/* .carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
    display: flex;
}


.carousel-inner .carousel-item-end,
.carousel-inner .carousel-item-start {
    transform: translateX(0);
} */

/* star container */
.stars-container {
  position: relative;
  display: inline-block;
  color: transparent;
}

.stars-container:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "★★★★★";
  color: lightgray;
}

.stars-container:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "★★★★★";
  color: var(--red-color);
  overflow: hidden;
}

.stars-90:after {
  width: 91%;
}

/*react date picker */
.react-datepicker__month-select {
  margin: 5px;
  margin-right: 35px;
}

/* library css */

.pincode-input-text {
  margin: 0px 1px !important;
  height: 48px !important;
}

@media screen and (max-width: 350px) {
  .productPlanPage .pincodeAvailibility .availibilityContainer button {
    margin-top: 5px;
  }
}

@media screen and (min-width: 767px) {
  .pincode-input-text {
    margin: 0px 5px !important;
  }
}

#accordionExampleOne .accordion-button:not(.collapsed) {
  background-color: #f5f5f5;
}

.paytm-logo img {
  scale: 1.5;
}

/* knowMoreButton */
.knowMoreButton {
  border-radius: 15px;
  /* padding: 1px; */
  font-size: 14px;
  /* border: 1px solid var(--red-color); */
  /* color: var(--red-color); */
  border: none;
  text-decoration: underline;
  margin-left: 35px;
}

/*  */

/* discountedAmountComponent */
.discountedAmountComponent {
  display: flex;
  width: 250px;
  height: 45px;
}

.discountedAmountComponent div {
  text-align: center;
  padding: 1px;
}

.discountedAmountComponent .discount-upto {
  border: 1px solid var(--red-color);
  color: var(--red-color);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.discountedAmountComponent .discounted-price {
  background-color: green;
  color: white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

/* new ui css */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* font-family: 'Poppins', sans-serif; */

/* ============== ROOT VARIABLES================== */
:root {
  --red-color: #dc332e;
  --black-grey: #4f4f4f;
  --black-color: #333333;
  --white-color: #fff;
  --border-radius-1: 10px;

  --trans: all 0.3s ease-in-out;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.card-border-radius {
  border-radius: var(--border-radius-1);
}

/* ===============BACKGROUND COLORS================== */
.bg-red-color {
  background-color: var(--red-color);
}

/* ===============FONT COLORS================== */
.text-black {
  color: var(--black-color);
}

.text-black-grey {
  color: var(--black-grey);
}

.text-white {
  color: var(--white-color);
}

.text-red {
  color: var(--red-color);
}

/* ===============FONT SIZES================== */
.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 11px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.fs-15 {
  font-size: 15px;
}

.fs-16 {
  font-size: 1rem;
}

.fs-17 {
  font-size: 17px;
}

.fs-18 {
  font-size: 18px;
}

.fs-19 {
  font-size: 19px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-26 {
  font-size: 26px;
}

.fs-28 {
  font-size: 28px;
}

.fs-30 {
  font-size: 30px;
}

/* ===============FONT WEIGHTS================== */
.fw-1 {
  font-weight: 100;
}

.fw-2 {
  font-weight: 200;
}

.fw-3 {
  font-weight: 300;
}

.fw-4 {
  font-weight: 400;
}

.fw-5 {
  font-weight: 500;
}

.fw-6 {
  font-weight: 600;
}

.fw-7 {
  font-weight: 700;
}

.fw-8 {
  font-weight: 800;
}

.white-btn {
  font-size: 16px;
  line-height: 24px;
  background: var(--white-color);
  border-radius: 10px;
  padding: 13px 60px;
  color: var(--red-color);
  border: 0px;
  display: inline-block;
}

.red-btn {
  font-size: 16px;
  line-height: 24px;
  background: var(--red-color);
  border-radius: 10px;
  padding: 13px 60px;
  color: var(--white-color);
  border: 0px;
  display: inline-block;
}

a,
a:hover,
a:focus {
  color: #333;
  text-decoration: none !important;
  transition: all 0.3s;
}

ul,
li {
  text-decoration: none;
  list-style: none;
}

body {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
}

/* HEADER CSS */
header {
  background: url(../images/header-bg-n.png);
}

header .navbar {
  padding: 24px 0;
}

header .navbar-nav .nav-item .nav-link {
  font-size: 16px;
  font-weight: 400;
  color: var(--black-color);
  text-align: center;
  cursor: pointer;
}

header .navbar-nav .nav-item .nav-link.active {
  font-weight: 600;
  cursor: pointer;
}

header .navbar-nav .nav-item {
  margin: 0 16px;
}

header .navbar-nav .nav-item:last-child {
  margin-right: 0;
}

/* HERO SECTION CSS */
#heroSection .heroSectionWrapper {
  background: url(../images/banner-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: bottom; */
}

#heroSection .heroSectionWrapperMobile {
  background: url(../images/Frame-4.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  height: 700px;
}

@media screen and (min-width: 1800px) {
  #heroSection .heroSectionWrapperMobile {
    height: 860px;
  }
}

#heroSection .heroSectionWrapper .leftContent {
  padding: 121px 0;
}

#heroSection .leftContent h1 {
  font-weight: 800;
  font-size: 48px;
  line-height: 75px;
  letter-spacing: 0.03em;
  color: var(--white-color);
}

#heroSection .leftContent p {
  font-size: 18px;
  line-height: 34px;
  color: var(--white-color);
  padding: 30px 0;
  padding-right: 50px;
}

#heroSection .leftContent .white-btn {
  padding: 13px 28px;
}

#heroSection .leftContent .bannerArrow {
  position: absolute;
  top: -45px;
  left: 180px;
}

#heroSection .rightContent {
  padding-top: 20px;
  display: flex;
  align-items: flex-end;
}

#heroSection .rightContent img {
  max-width: 100%;
  margin-bottom: 6%;
  margin-left: 2%;
  scale: 1.19;
}

/* PLAN SECTION CSS */

#planSection .planSectionWrapper {
  padding: 100px 0 140px 0;
  background-color: #fff;
}

#planSection .planHeader {
  margin-bottom: 80px;
}

#planSection .planHeader h2 {
  font-size: 40px;
  font-weight: 400;
  text-align: center;
  color: var(--black-color);
  margin-bottom: 20px;
}

#planSection .planHeader p {
  font-size: 20px;
  text-align: center;
  color: var(--black-color);
}

#planSection .planCardWrapper .planCard {
  background: #ffffff;
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  padding: 30px 20px;
  margin: 0 12px;
  min-height: 749px;
  margin-bottom: 25px;
  width: 420px;
}

#planSection .planCard .planCardHeader {
  /* padding-bottom:10px ; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 45px;
}

#planSection .planCard .planCardHeader p {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: var(--black-color);
  display: inline-block;
  margin-bottom: 0;
}

#planSection .planCard .planCardHeader span {
  font-weight: 600;
  font-size: 12px;
  color: var(--red-color);
  padding: 5px 10px;
  display: inline-block;
  background-color: rgba(220, 51, 46, 0.1);
  border-radius: 6px;
}

#planSection .planCard hr {
  border-top: 1px solid #333333;
}

#planSection .planCard .planCardBody .iconBoxWrapper {
  display: flex;
  align-items: flex-start;
  padding: 16px 0;
  min-height: 230px;
}

#planSection .planCard .planCardBody .iconBoxWrapper .iconBox {
  text-align: center;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#planSection .planCard .planCardBody .iconBoxWrapper .iconBox:last-child {
  padding-right: 0px;
}

#planSection .planCard .planCardBody .iconBoxWrapper .iconBox img {
  width: 65px;
  height: 65px;
  border-radius: 10%;
}

#planSection .planCard .planCardBody .iconBoxWrapper .iconBox .parameterIcon {
  width: 110px;
  height: 110px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  margin-bottom: 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4f4f4f;
}

#planSection .planCard .planCardBody .iconBoxWrapper .iconBox p {
  font-size: 19px;
  font-weight: 500;
  color: var(--black-color);
}

#planSection .planCard .planCardBody ul {
  padding-left: 0;
  margin-bottom: 0;
  min-height: 208px;
}

#planSection .planCard .planCardBody ul li {
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  color: #4f4f4f;
  display: flex;
  align-items: start;
  padding: 12px 0;
}

#planSection .planCard .planCardBody ul li img {
  margin-top: 4px;
  margin-right: 10px;
}

#planSection .planCard .planCardBody .priceWrapper {
  background-color: rgba(220, 51, 46, 0.1);
  border-radius: 7px;
  display: flex;
  align-items: center;
  margin: 35px 0;
}

#planSection .planCard .planCardBody .priceWrapper .percent {
  width: 40%;
  border-right: 1px solid rgba(220, 51, 46, 0.2);
  text-align: center;
}

#planSection .planCard .planCardBody .priceWrapper .percent p {
  font-size: 34px;
  font-weight: 700;
  color: var(--red-color);
  margin-bottom: 0;
  padding: 15px 0;
}

#planSection .planCard .planCardBody .priceWrapper .price {
  width: 60%;
  text-align: center;
}

#planSection .planCard .planCardBody .priceWrapper .price p {
  font-size: 23px;
  font-weight: 400;
  color: #4f4f4f;
  margin-bottom: 0;
  padding: 15px 0;
}

#planSection .planCard .planCardFooter {
  text-align: center;
}

#planSection .planCard .planCardFooter a {
  margin-right: 45px;
  color: #4f4f4f;
  cursor: pointer;
}

#planSection .planCard .planCardFooter button {
  margin-left: 45px;
}

/* PINCODE SECTION CSS */

#pincodeSection .pincodeSectionWrapper {
  padding: 45px 0;
  background-color: var(--red-color);
  /* background-color: rgba(220, 51, 46, 0.2); */
}

#pincodeSection .leftContent p {
  font-weight: 600;
  font-size: 34px;
  line-height: 51px;
  color: var(--white-color);
  margin-bottom: 0;
}

#pincodeSection .rightContent .pincodeInput {
  border-radius: 1px solid var(--red-color);
  background-color: #ffffff;
  border-radius: 10px;
  width: 80%;
  margin: auto;
  display: flex;
  align-items: center;
  padding: 0 3px;
}

#pincodeSection .rightContent .pincodeInput > img {
  padding: 13px 10px 13px 20px;
}

#pincodeSection .rightContent .pincodeInput input {
  width: 100%;
  font-size: 14px;
  color: #828282;
  border: 0;
  outline: none;
}

#pincodeSection .rightContent .pincodeInput button {
  border: 0;
  background: none;
  border-left: 1px solid var(--red-color);
  padding: 13px 20px;
}

/* QUIZ SECTION CSS */

#quizSection .quizSectionWrapper {
  padding: 130px 0;
  background-color: #fff;
}

#quizSection .quizSectionWrapper .leftContent img {
  max-width: 100%;
}

#quizSection .quizSectionWrapper .rightContent h3 {
  font-size: 34px;
  line-height: 51px;
}

#quizSection .quizSectionWrapper .rightContent p {
  font-size: 18px;
  line-height: 36px;
  color: #000000;
  margin-bottom: 30px;
  padding-right: 25%;
}

#quizSection .quizSectionWrapper .rightContent a:hover {
  color: #fff;
  cursor: pointer;
}

/* QUALITY SECTION CSS */
#qualitySection .qualitySectionWrapper {
  background: url(../images/quality-care-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

#qualitySection .qualitySectionWrapper .leftContent h3 {
  font-size: 34px;
  line-height: 51px;
  color: var(--white-color);
  margin-left: 12px;
  font-weight: 400;
}

#qualitySection .qualitySectionWrapper .leftContent .boxWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  /* justify-content: space-between; */
}

#qualitySection .qualitySectionWrapper .leftContent .boxWrapper .box {
  max-width: 230px;
  border-radius: 19px;
  background-color: var(--white-color);
  padding: 35px 0;
  text-align: center;
  margin: 8px;
  flex-basis: 30%;
}

#qualitySection .qualitySectionWrapper .leftContent .boxWrapper .box h4 {
  font-size: 37px;
  font-weight: 700;
  color: #4f4f4f;
}

#qualitySection .qualitySectionWrapper .leftContent .boxWrapper .box p {
  font-size: 12px;
  color: #4f4f4f;
  margin-bottom: 0;
}

#qualitySection .qualitySectionWrapper .rightContent {
  padding: 55px 0;
}

#qualitySection .qualitySectionWrapper .rightContent img {
  max-width: 100%;
}

/* WORK SECTION CSS */
#workSection {
  width: 100%;
  /* height: 100vh; */
  /* overflow-y: hidden; */
  /* scroll-snap-type: y mandatory; */
}

/* #workSection::-webkit-scrollbar {
    display: none;
}

#workSection {
    -ms-overflow-style: none;
    scrollbar-width: none;
} */

#workSection .workSectionWrapper {
  padding: 0 0 100px 0;
  background-color: #f9f9f9;
  /* height: 100vh; */
  width: 100%;
  /* scroll-snap-align: start; */
}

#workSection .workHeader {
  margin-bottom: 50px;
  /* text-align: center; */
  /* position: sticky; */
  /* top: 0; */
  padding-top: 100px;
  background-color: #f9f9f9;
  z-index: 3;
}

#workSection .workHeader h2 {
  font-size: 34px;
  color: var(--black-color);
  margin-bottom: 20px;
  font-weight: 400;
}

#workSection .workHeader p {
  font-size: 18px;
  /* line-height: 36px; */
  color: #000000;
}

#workSection .leftContent {
  position: sticky;
  top: 0px;
}

#workSection .leftContent .stepWrapper {
  padding: 50px 35px 50px 0;
  border-right: 2px solid #e0e0e0;
}

#workSection .leftContent .stepWrapper ul {
  margin-bottom: 0;
}

#workSection .leftContent .stepWrapper ul li {
  padding: 12px 15px;
  background: #ffffff;
  border: 1px solid #dc332e;
  border-radius: 3px;
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #4f4f4f;
}

#workSection .leftContent .stepWrapper ul li.active {
  background: #dc332e;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  font-weight: 600;
  color: var(--white-color);
  font-size: 18px;
  /* -webkit-transition: all 1s ease-out;
    -moz-transition: all 1s ease-out;
    -o-transition: all 1s ease-out;
    transition: all 1s ease-out; */
}

#workSection .leftContent .stepWrapper ul li:last-child {
  margin-bottom: 0;
}

#workSection .leftContent .para p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #4f4f4f;
  margin-left: 5px;
}

#workSection .rightContent img {
  max-width: 100%;
  margin-top: 350px;
}

#workSection .rightContent.step1Image img {
  max-width: 93%;
}

/* WORK SECTION FOR MOBILE CSS */
#workSectionMobile .workSectionWrapperMobile {
  background-color: #f9f9f9;
  padding: 70px 0;
}

#workSectionMobile .workHeader {
  margin-bottom: 40px;
  text-align: center;
}

#workSectionMobile .workHeader h2 {
  font-size: 25px;
  color: var(--black-color);
  line-height: 38px;
  margin-bottom: 15px;
  font-weight: 400;
}

#workSectionMobile .workHeader p {
  font-size: 16px;
  line-height: 29px;
  color: #000000;
}

#workSectionMobile .workHeader p br {
  display: none;
}

#workSectionMobile .workContent {
  margin-bottom: 60px;
}

#workSectionMobile .workContent .workContentHeading {
  text-align: center;
}

#workSectionMobile .workContent .workContentHeading h2 {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.04em;
  color: #dc332e;
}

#workSectionMobile .workContent .workContentHeading p {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #4f4f4f;
  margin-bottom: 25px;
}

#workSectionMobile .workContent .imageWrapper {
  text-align: center;
}

#workSectionMobile .workContent .imageWrapper img {
  max-width: 90%;
}

/* HEALTHWAY SECTION CSS */

#healthwaySection .healthwaySectionWrapper .leftContent {
  /* background: url(../images/healthway-work-left.png); */
  background-color: #fbeaea;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
  min-height: 480px;
  position: relative;
}

#healthwaySection .healthwaySectionWrapper .leftContent .contentWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  /* width: 58%; */
  /* padding: 50px 10px 60px 10px; */
  display: flex;
  /* flex-direction: column; */
  align-items: center;
}

#healthwaySection
  .healthwaySectionWrapper
  .leftContent
  .contentWrapper
  .imgContentWrapper
  img {
  height: 424px;
  width: 300px;
}

#healthwaySection .healthwaySectionWrapper .leftContent .contentWrapper h4 {
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  color: var(--black-color);
  text-align: center;
  margin-bottom: 0;
}

#healthwaySection
  .healthwaySectionWrapper
  .leftContent
  .contentWrapper
  .contentCard
  p {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: white;
  padding: 29px 5px;
  border-radius: 11px;
  background: #828282;
  margin: 45px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* text-align: center; */
}
/* #healthwaySection
  .healthwaySectionWrapper
  .leftContent
  .contentWrapper
  .contentCard
  p span{
    width: 200px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: 0.9px;
  } */

#healthwaySection .healthwaySectionWrapper .leftContent .contentWrapper p span {
  display: inline-block;
  text-align: center;
  /* margin-left: 1rem;
  margin-right: 1rem; */
}

#healthwaySection .healthwaySectionWrapper .leftContent .contentWrapper button {
  background: #828282;
  width: 200px;
}

#healthwaySection
  .healthwaySectionWrapper
  .leftContent
  .contentWrapper
  p
  span.plus {
  padding: 0 10px;
}

#healthwaySection .healthwaySectionWrapper .leftContent table {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  position: absolute;
  top: 122px;
  left: 54px;
}

#healthwaySection .healthwaySectionWrapper .leftContent table tr th,
#healthwaySection .healthwaySectionWrapper .leftContent table tr td {
  padding: 8px 20px 8px 20px;
  text-align: left;
  font-size: 12px;
}

#healthwaySection .healthwaySectionWrapper .leftContent table tr > th {
  padding-top: 15px;
}

#healthwaySection .healthwaySectionWrapper .leftContent table tr:last-child td {
  padding-bottom: 15px;
}

#healthwaySection .healthwaySectionWrapper .rightContent {
  background: url(../images/healthway-work-right1.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
  min-height: 480px;
  position: relative;
  background-color: #db2228;
}

#healthwaySection .healthwaySectionWrapper .rightContent .contentWrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 70%;
  padding: 73px 10px 60px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fbeaea;
  height: 455px;
}

#healthwaySection .healthwaySectionWrapper .rightContent .contentWrapper h4 {
  font-weight: 700;
  font-size: 22px;
  line-height: 33px;
  color: var(--black-color);
  text-align: center;
  margin-bottom: 0;
}

#healthwaySection .healthwaySectionWrapper .rightContent .contentWrapper p {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: var(--red-color);
  padding: 35px 10px;
  border-radius: 11px;
  background-color: rgba(220, 51, 46, 0.1);
  margin: 30px 0;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* text-align: center; */
}
/* #healthwaySection
  .healthwaySectionWrapper
  .rightContent
  .contentWrapper
  .contentCard
  p span{
    width: 200px;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: 0.9px;
  } */

#healthwaySection
  .healthwaySectionWrapper
  .rightContent
  .contentWrapper
  p
  span {
  display: inline-block;
  text-align: center;
  /* margin-left: 1rem;
  margin-right: 1rem; */
}

#healthwaySection
  .healthwaySectionWrapper
  .rightContent
  .contentWrapper
  p
  span.plus {
  padding: 0 10px;
}

/* WHYCHOOSE SECTION CSS */
#whyChooseSection .whyChooseSectionWrapper {
  padding: 70px 0;
  background: url(../images/why-choose-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

#whyChooseSection .topHeading h3 {
  font-weight: 300;
  font-size: 34px;
  color: var(--white-color);
  text-align: center;
  margin-bottom: 70px;
}

#whyChooseSection .whyChooseCard {
  padding: 38px 18px;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  min-height: 300px;
  margin: 0 5px;
}

#whyChooseSection .whyChooseCard img {
  margin-bottom: 20px;
  width: 80px;
  height: 80px;
  border-radius: 8px;
}

#whyChooseSection .whyChooseCard h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: var(--black-color);
  letter-spacing: 0.02em;
  text-align: left;
}

#whyChooseSection .whyChooseCard p {
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: rgba(79, 79, 79, 0.8);
  letter-spacing: 0.02em;
  text-align: left;
}

/* NEWS SECTION CSS */
#newsSection .newsSectionWrapper {
  padding: 100px 0;
  background-color: var(--white-color);
}

#newsSection .newsHeader {
  margin-bottom: 35px;
  text-align: center;
}

#newsSection .newsHeader h2 {
  font-weight: 400;
  font-size: 34px;
  color: var(--black-color);
  margin-bottom: 16px;
}

#newsSection .newsHeader p {
  font-weight: 400;
  font-size: 18px;
  line-height: 36px;
  color: #000000;
}

#newsSection .newsLogo {
  text-align: center;
}

#newsSection .newsLogo img {
  max-width: 100%;
}

/* TESTIMONIAL SECTION CSS */

/* .react-multi-carousel-item {
    width: 275px !important;
} */

.carousel-indicators button.active {
  background-color: #dc332e !important;
}

.carousel-indicators button {
  background-color: #dc342e7a !important;
}

#testimonialSection .testimonialSectionWrapper {
  padding: 70px 0;
  background-color: #f9f9f9;
}

#testimonialSection .testimonialHeader h2 {
  font-weight: 400;
  font-size: 34px;
  text-align: center;
  color: var(--black-color);
  margin-bottom: 50px;
}

#testimonialSection .testimonialCard {
  padding: 25px;
  min-height: 300px;
  background: rgba(220, 51, 46, 0.06);
  border-radius: 10px;
  margin: 10px;
  min-height: 95%;
}

#testimonialSection .testimonialCard .cardTop {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

#testimonialSection .testimonialCard .cardTop img {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  margin-right: 19px;
}

#testimonialSection .testimonialCard .cardTop .cardTopText h4 {
  font-weight: 600;
  font-size: 16px;
  color: var(--black-color);
  margin-bottom: 4px;
}

#testimonialSection .testimonialCard .cardTop .cardTopText p {
  font-size: 12px;
  font-weight: 400;
  color: #727272;
  margin-bottom: 0;
}

#testimonialSection .testimonialCard .cardBody p {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: var(--black-color);
}

/* FAQ SECTION CSS */
#faqSection .faqSectionWrapper,
#faqPageSection .faqSectionWrapper {
  padding: 100px 0;
}

#faqSection .faqHeader h2,
#faqPageSection .faqHeader h2 {
  font-weight: 700;
  font-size: 34px;
  color: var(--black-color);
  text-align: center;
}

#faqPageSection .faqHeader h3 {
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.02em;
  color: #000000;
  width: 95%;
  padding: 13px 30px;
  background: rgba(220, 51, 46, 0.1);
  margin: auto;
  margin-top: 60px;
}

#faqSection .faqContentWrapper {
  margin: 60px 0;
}

#faqPageSection .faqContentWrapper {
  margin: 30px 0 0 0;
}

#faqSection .faqContentWrapper .accordion,
#faqPageSection .faqContentWrapper .accordion {
  max-width: 800px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 4px 28px rgba(33, 109, 180, 0.1);
  border-radius: 20px;
  border: none;
  padding: 30px;
  overflow: hidden;
}

#faqPageSection .faqContentWrapper .accordion {
  max-width: 95%;
  box-shadow: none;
  border-radius: none;
  padding: 10px;
}

#faqSection .faqContentWrapper .accordion .accordion-item,
#faqPageSection .faqContentWrapper .accordion .accordion-item {
  border: 0;
  background-color: #fff;
}

#faqSection .faqContentWrapper .accordion .accordion-item:focus-visible,
#faqPageSection .faqContentWrapper .accordion .accordion-item:focus-visible {
  outline: 0;
}

#faqSection .accordion-header:focus-visible,
#faqPageSection .accordion-header:focus-visible {
  outline: 0;
}

#faqSection .accordion-button,
#faqPageSection .accordion-button {
  font-weight: 600;
  font-size: 20px !important;
  color: var(--black-color);
}

#faqSection .accordion-button:focus,
#faqPageSection .accordion-button:focus {
  border-color: #fff !important;
  box-shadow: none !important;
}

#faqSection .accordion-button:not(.collapsed),
#faqPageSection .accordion-button:not(.collapsed) {
  font-weight: 600;
  font-size: 20px !important;
  background-color: #fff !important;
  color: var(--black-color) !important;
  box-shadow: none !important;
}

#faqSection .accordion-body,
#faqPageSection .accordion-body {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 1rem 0 !important;
  margin: 0 1.25rem;
  margin-bottom: 20px;
}

#faqSection .accordion-body p,
#faqPageSection .accordion-body p {
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
  color: rgba(0, 0, 0, 0.8);
  margin-bottom: 0;
}

#faqSection .btnWrapper a {
  text-decoration: none;
  display: inline-block;
}

#faqSection .btnWrapper a:hover {
  color: var(--white-color);
  text-decoration: none;
}

/* CALLBACK SECTION CSS */
#callBackSection .callBackSectionWrapper {
  margin: 45px 0 100px 0;
}

#callBackSection .callBackWrapper {
  padding: 55px;
  background: #ffdedd;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

#callBackSection .callBackHeader {
  margin-bottom: 55px;
  text-align: center;
}

#callBackSection .callBackHeader h2 {
  font-weight: 300;
  font-size: 30px;
  /* color: var(--white-color); */
  margin-bottom: 20px;
}

#callBackSection .callBackHeader p {
  font-weight: 400;
  font-size: 20px;
  color: #333333;
  margin-bottom: 0px;
}

#callBackSection .fieldWrapper {
  padding: 0 15px;
  margin-bottom: 60px;
}

#callBackSection .formWrapper .form-label {
  font-weight: 400;
  font-size: 16px;
  /* color: #fff; */
  letter-spacing: 0.03em;
}

#callBackSection .formWrapper .form-control {
  padding: 16px 10px 16px 0;
  border: 0;
  border-bottom: 1px solid #fff;
  border-radius: 0;
  background-color: transparent;
  /* color: rgba(255, 255, 255, 0.8); */
  font-size: 18px;
  letter-spacing: 0.03em;
}

#callBackSection .formWrapper .form-control::placeholder {
  /* color: rgba(255, 255, 255, 0.4); */
}

#callBackSection .formWrapper .form-control:focus {
  box-shadow: none !important;
}

/* FOOTER SECTION CSS */
#footerSection .footerLeftWrapper {
  padding: 50px 0 100px 0;
  width: 89%;
  margin-left: auto;
}

#footerSection .footerLeftWrapper .leftContent img {
  margin-bottom: 26px;
}

#footerSection .footerLeftWrapper .leftContent p {
  font-size: 14px;
  color: var(--white-color);
  margin-bottom: 32px;
  line-height: 28px;
}

#footerSection .footerLeftWrapper .rightContent {
  display: flex;
  align-items: flex-start;
}

#footerSection .footerLeftWrapper .rightContent ul {
  flex-basis: 50%;
}

#footerSection .footerLeftWrapper .rightContent ul li {
  font-size: 14px;
  margin-bottom: 15px;
  color: var(--white-color);
}

#footerSection .footerLeftWrapper .rightContent ul li a {
  display: inline-block;
  color: var(--white-color);
  text-decoration: none;
}

#footerSection .copyright {
  width: 89%;
  margin-left: auto;
  margin-bottom: 20px;
}

#footerSection .copyright p {
  font-weight: 500;
  font-size: 14px;
  color: var(--white-color);
}

#footerSection .footerRightWrapper {
  padding: 33px 0 65px 35px;
  width: 83%;
  margin-right: auto;
}

#footerSection .footerRightWrapper .top h3 {
  font-weight: 300;
  font-size: 30px;
  line-height: 48px;
  color: var(--black-color);
}

#footerSection .footerRightWrapper .middle {
  margin: 28px 0;
  background: #ffffff;
  border: 1px solid #2f4f4f;
  border-radius: 6px;
  padding: 11px 14px;
  display: flex;
  align-items: center;
}

#footerSection .footerRightWrapper .middle input {
  width: 100%;
  padding: 0;
  padding-right: 12px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  border: 0;
}

#footerSection .footerRightWrapper .middle input:focus-visible {
  outline: 0;
}

#footerSection .footerRightWrapper .middle button {
  border: 0;
  background: none;
  outline: 0;
}

#footerSection .footerRightWrapper .bottom p {
  font-size: 16px;
  line-height: 34px;
  color: rgba(0, 0, 0, 0.8);
}

/* STEPS PAGE CSS */
.stepWrapper {
  background: #f5f5f5;
}

.stepWrapper .stepWrapperBox {
  min-height: 100vh;
  padding: 35px 35px 35px 35px;
  background: #ffffff;
  box-shadow: 0px 0px 34px rgba(9, 105, 159, 0.08);
  border-radius: 20px;
  max-width: 720px;
  margin: auto;
}

.stepWrapper .stepWrapperBox .stepHeader .stepText {
  font-weight: 400;
  font-size: 14px;
  color: var(--black-color);
  margin-bottom: 5px;
}

.stepWrapper .stepWrapperBox .stepHeader h1 {
  font-weight: 600;
  font-size: 34px;
  margin-bottom: 10px;
  line-height: 51px;
}

.stepWrapper .stepWrapperBox .stepHeader p {
  font-size: 16px;
  line-height: 28px;
  color: #4f4f4f;
  margin-bottom: 20px;
}

.stepWrapper .stepWrapperBox .stepBody {
  min-height: 425px;
}

.stepWrapper .stepWrapperBox .stepBody.stepBody5 {
  min-height: 374px;
}

.stepWrapper .stepWrapperBox .stepBody .col-md-3 {
  padding: 0;
}

.stepWrapper .stepWrapperBox .stepBody .stepBodyCard {
  background: rgba(176, 176, 176, 0.1);
  border: 1px solid #828282;
  border-radius: 9px;
  text-align: center;
  padding: 30px 10px 16px 10px;
  min-height: 150px;
  margin: 9px;
  cursor: pointer;
}

.stepWrapper .stepWrapperBox .stepBody .stepBodyCard p {
  font-size: 14px;
  line-height: 21px;
  color: var(--black-color);
  margin-bottom: 0;
}

.stepWrapper .stepWrapperBox .stepBody .stepBodyCard .image {
  height: 60px;
}

.stepWrapper .stepWrapperBox .stepFooter .btnWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.stepWrapper .stepWrapperBox .stepFooter .btnWrapper .backBtn button {
  width: 181px;
  text-align: center;
  padding: 13px 0;
  background: rgba(220, 51, 46, 0.1);
  border-radius: 10px;
  color: #dc332e;
  display: inline-block;
  border: 0;
}

.stepWrapper .stepWrapperBox .stepFooter .btnWrapper .nextBtn button {
  width: 181px;
  text-align: center;
  padding: 13px 0;
  background: #dc332e;
  border-radius: 10px;
  color: #fff;
  display: inline-block;
  border: 0;
}

.stepWrapper .stepWrapperBox .stepBody .stepBodyCard:hover {
  /* background: #dc332e; */
  border: 1px solid #dc332e;
}

.stepWrapper .stepWrapperBox .stepBody .stepBodyCard:hover p {
  /* color: #fff; */
}

.stepWrapper .stepWrapperBox .stepBody .stepBodyCard:hover img {
  /* filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(92deg)
    brightness(109%) contrast(101%); */
}

.stepWrapper .stepWrapperBox .stepBody .stepBodyCard.active {
  background: #dc332e;
  border: 1px solid #dc332e;
}

.stepWrapper .stepWrapperBox .stepBody .stepBodyCard.active p {
  color: #fff;
}

.stepWrapper .stepWrapperBox .stepBody .stepBodyCard.active img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(92deg)
    brightness(109%) contrast(101%);
}

.stepWrapper .stepBody .ratingWrapper {
  padding-top: 50px;
}

.stepWrapper .stepBody .ratingWrapper .ratingScale {
  display: flex;
}

.stepWrapper .stepBody .ratingWrapper .ratingScale span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  letter-spacing: 0.02em;
  color: #fff;
  padding: 14px 4px;
}

.stepWrapper .stepBody .ratingWrapper .ratingScale label {
  flex-basis: 10%;
}

.stepWrapper .stepBody .ratingWrapper .ratingScale input[type="radio"] {
  display: none;
}

.stepWrapper
  .stepBody
  .ratingWrapper
  .ratingScale
  input[type="radio"]:checked
  ~ span {
  border: 3px solid black;
}

.rating1 {
  background: rgba(220, 51, 46, 1);
  border: 3px solid rgba(220, 51, 46, 1);
}

.rating2 {
  background: rgba(196, 72, 49, 1);
  border: 3px solid rgba(196, 72, 49, 1);
}

.rating3 {
  background: rgba(181, 85, 52, 1);
  border: 3px solid rgba(181, 85, 52, 1);
}

.rating4 {
  background: rgba(164, 99, 56, 1);
  border: 3px solid rgba(164, 99, 56, 1);
}

.rating5 {
  background: rgba(147, 113, 60, 1);
  border: 3px solid rgba(147, 113, 60, 1);
}

.rating6 {
  background: rgba(130, 128, 64, 1);
  border: 3px solid rgba(130, 128, 64, 1);
}

.rating7 {
  background: rgba(111, 143, 69, 1);
  border: 3px solid rgba(111, 143, 69, 1);
}

.rating8 {
  background: rgba(93, 158, 73, 1);
  border: 3px solid rgba(93, 158, 73, 1);
}

.rating9 {
  background: rgba(75, 173, 77, 1);
  border: 3px solid rgba(75, 173, 77, 1);
}

.rating10 {
  background: rgba(61, 185, 80, 1);
  border: 3px solid rgba(61, 185, 80, 1);
}

/* STEPS-6 PAGE CSS */
.stepWrapper .stepBody .formWrapper {
  min-height: 523px;
}

.stepWrapper .stepBody .formWrapper .fieldWrapper {
  margin-bottom: 30px;
}

.stepWrapper .stepBody .formWrapper .form-label {
  font-weight: 600;
  font-size: 24px;
  color: var(--black-color);
  margin-bottom: 17px;
}

.stepWrapper .stepBody .formWrapper .form-select,
.stepWrapper .stepBody .formWrapper .form-control.height,
.stepWrapper .stepBody .formWrapper .form-control.weight {
  background-color: rgba(176, 176, 176, 0.1);
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  /* color: #bdbdbd; */
  background-image: none;
  border: 0;
  padding: 12px 15px;
}

.stepWrapper .stepBody .formWrapper .form-select:focus,
.stepWrapper .stepBody .formWrapper .form-control.height:focus,
.stepWrapper .stepBody .formWrapper .form-control.weight:focus,
.stepWrapper .stepBody .formWrapper .fieldWrapper .bmi:focus {
  box-shadow: none;
}

.stepWrapper .stepBody .formWrapper .fieldWrapper .genderRadio,
.stepWrapper .stepBody .formWrapper .fieldWrapper .bmi {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: var(--black-color);
  width: 156px;
  padding: 12px;
  background: rgba(176, 176, 176, 0.1);
  border: 1px solid #828282;
  border-radius: 9px;
  margin-right: 18px;
}

.stepWrapper .stepBody .formWrapper .fieldWrapper input[type="radio"] {
  display: none;
}

.stepWrapper
  .stepBody
  .formWrapper
  .fieldWrapper
  input[type="radio"]:checked
  ~ .genderRadio {
  border: 1px solid #dc332e;
  background: var(--red-color);
  color: var(--white-color);
}

/* STEPS-7 PAGE CSS */
.step7Wrapper .stepHeader img {
  max-width: 100%;
}

.step7Wrapper .stepWrapperBox .stepBody {
  min-height: 375px;
  padding: 20px 45px 0 45px;
}

.step7Wrapper .stepBody p {
  font-size: 14px;
  color: var(--black-color);
  margin-bottom: 24px;
}

.step7Wrapper .stepBody h2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: var(--black-color);
  margin-bottom: 20px;
}

.step7Wrapper .stepBody .step7FormWrapper .inputField {
  position: relative;
  margin-bottom: 20px;
}

.step7Wrapper .stepBody .step7FormWrapper .inputField .form-label {
  font-weight: 400;
  font-size: 14px;
  color: #4f4f4f;
  padding: 0 10px;
  background: #fff;
  position: absolute;
  margin-left: 10px;
}

.step7Wrapper .stepBody .step7FormWrapper .inputField .form-control {
  font-size: 14px;
  color: var(--black-color);
  padding: 12px;
  background: #f9f9f9;
  border: 1px solid #828282;
  border-radius: 9px;
  margin-top: 10px;
}

.step7Wrapper .stepBody .step7FormWrapper .inputField .form-control:focus {
  box-shadow: none;
}

.step7Wrapper .stepBody .text p {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: var(--black-color);
  margin-top: 20px;
}

.step7Wrapper .stepFooter {
  padding: 0 45px 45px 45px;
}

/* HEALTHWAY PAGE CSS */
/* HEALTH SECTION CSS */
#healthSection .healthSectionWrapper {
  padding: 125px 0;
  background: #fff;
}

#healthSection .leftContent img {
  max-width: 100%;
}

#healthSection .rightContent .healthCard .healthCardHeader p {
  font-size: 34px;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: #4f4f4f;
  padding-bottom: 14px;
  margin-bottom: 0;
}

#healthSection .healthCard hr {
  border-top: 1px solid #333333;
}

#healthSection .healthCard .healthCardBody .iconBoxWrapper {
  display: flex;
  align-items: flex-start;
  padding: 12px 0;
}

#healthSection .healthCard .healthCardBody .iconBoxWrapper .iconBox {
  flex-basis: 25%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#healthSection
  .healthCard
  .healthCardBody
  .iconBoxWrapper
  .iconBox
  .parameterIcon {
  width: 70px;
  height: 70px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  margin-bottom: 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4f4f4f;
  /* margin-left: 10px; */
}

#healthSection .healthCard .healthCardBody .iconBoxWrapper .iconBox img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin-bottom: 16px;
}

#healthSection .healthCard .healthCardBody .iconBoxWrapper .iconBox p {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: var(--black-color);
}

#healthSection .healthCard .healthCardBody ul {
  padding-left: 0;
  margin-bottom: 0;
}

#healthSection .healthCard .healthCardBody ul li {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

#healthSection .healthCard .healthCardBody ul li span {
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  color: #4f4f4f;
  padding: 12px 0;
  display: inline-block;
  border-bottom: 1px solid rgba(79, 79, 79, 0.1);
  width: 90%;
}

#healthSection .healthCard .healthCardBody ul li:last-child span {
  border-bottom: 0;
}

#healthSection .healthCard .healthCardBody ul li img {
  margin-right: 10px;
}

#healthSection .healthCard .healthCardBody .btnWrapper {
  display: flex;
  align-items: flex-start;
  margin-top: 40px;
}

#healthSection .healthCard .healthCardBody .btnWrapper .priceWrapper {
  background-color: rgba(220, 51, 46, 0.1);
  border-radius: 7px;
  display: flex;
  align-items: center;
  flex-basis: 50%;
  margin-right: 13px;
  height: 50px;
}

#healthSection .healthCard .healthCardBody .priceWrapper .percent {
  flex-basis: 50%;
  border-right: 1px solid rgba(220, 51, 46, 0.2);
  text-align: center;
}

#healthSection .healthCard .healthCardBody .priceWrapper .percent p {
  font-size: 20px;
  font-weight: 700;
  color: var(--red-color);
  margin-bottom: 0;
}

#healthSection .healthCard .healthCardBody .priceWrapper .price {
  flex-basis: 50%;
  text-align: center;
}

#healthSection .healthCard .healthCardBody .priceWrapper .price p {
  font-size: 14px;
  font-weight: 400;
  color: #4f4f4f;
  margin-bottom: 0;
}

#healthSection .healthCard .healthCardBody .btnContent {
  flex-basis: 50%;
  height: 50px;
}

#healthSection .healthCard .healthCardBody .btnContent button {
  width: 100%;
}

/* BLOOD-TEST SECTION CSS */
#bloodTestSection .bloodTestSectionWrapper {
  padding: 80px 0;
  background: #f1f1f1;
}

#bloodTestSection .bloodTestHeader h2 {
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.02em;
  color: #4f4f4f;
  margin-bottom: 50px;
  text-align: center;
}

#bloodTestSection .bloodTestCard {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  padding: 20px;
  min-height: 280px;
  margin-bottom: 24px;
}

#bloodTestSection .bloodTestCard .cardTop h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.02em;
  color: #4f4f4f;
}

#bloodTestSection .bloodTestCard .cardTop hr {
  border: 1px solid rgba(79, 79, 79, 0.4);
  margin: 10px 0;
}

#bloodTestSection .bloodTestCard .cardBody p {
  font-weight: 500;
  font-size: 14px;
  line-height: 27px;
  color: #4f4f4f;
  margin: 20px 0 30px 0;
}

#bloodTestSection .bloodTestCard .cardBody .tagsWrapper p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #828282;
  margin-bottom: 15px;
}

#bloodTestSection .bloodTestCard .cardBody .tagsWrapper .tags span {
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  color: var(--black-color);
  padding: 5px 10px;
  background: rgba(220, 51, 46, 0.1);
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.fade-in-image {
  animation: fadeIn 2s;
  -webkit-animation: fadeIn 2s;
  -moz-animation: fadeIn 2s;
  -o-animation: fadeIn 2s;
  -ms-animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@media (max-width: 1255px) {
  .plan-section-responsive {
    width: 50%;
  }
  #heroSection .rightContent img {
    scale: 1;
  }
}

@media (max-width: 1280px) {
  #healthwaySection .healthwaySectionWrapper .rightContent .contentWrapper {
    width: 62%;
    height: 455px;
  }
  #heroSection .rightContent img {
    scale: 1;
  }
}

/* RESPONSIVE CSS */
@media (max-width: 1199px) {
  /*************** HOME PAGE RESONSIVE CSS **************/
  #heroSection .heroSectionWrapper .leftContent {
    padding: 60px 0;
  }

  #qualitySection .qualitySectionWrapper .leftContent .boxWrapper .box {
    max-width: 210px;
  }

  #testimonialSection .testimonialCard {
    min-height: 375px;
  }

  #whyChooseSection .whyChooseCard {
    min-height: 345px;
  }

  #healthwaySection .healthwaySectionWrapper .leftContent {
    min-height: 333px;
  }

  #healthwaySection .healthwaySectionWrapper .leftContent .contentWrapper {
    padding: 25px 10px;
  }

  #healthwaySection
    .healthwaySectionWrapper
    .leftContent
    .contentWrapper
    .imgContentWrapper
    img {
    height: 256px;
    width: 181px;
  }

  #healthwaySection .healthwaySectionWrapper .leftContent .contentWrapper h4 {
    margin-bottom: 0;
  }

  #healthwaySection .healthwaySectionWrapper .leftContent .contentWrapper p {
    font-size: 12px;
    padding: 35px 10px;
    margin: 20px 0;
    line-height: 17px;
  }

  #healthwaySection
    .healthwaySectionWrapper
    .leftContent
    .contentWrapper
    .red-btn {
    font-size: 14px;
    padding: 8px 30px;
  }

  #healthwaySection .healthwaySectionWrapper .rightContent {
    min-height: 333px;
  }

  #healthwaySection .healthwaySectionWrapper .leftContent table {
    top: 65px;
    left: 20px;
  }

  #healthwaySection .healthwaySectionWrapper .leftContent table tr th,
  #healthwaySection .healthwaySectionWrapper .leftContent table tr td {
    font-size: 10px;
    padding: 5px 10px 5px 10px;
  }

  #healthwaySection .healthwaySectionWrapper .rightContent .contentWrapper {
    padding: 25px 10px;
    height: 333px;
  }

  #healthwaySection .healthwaySectionWrapper .rightContent .contentWrapper h4 {
    margin-bottom: 0;
  }

  #healthwaySection .healthwaySectionWrapper .rightContent .contentWrapper p {
    font-size: 12px;
    padding: 35px 10px;
    margin: 20px 0;
    line-height: 17px;
  }

  #healthwaySection
    .healthwaySectionWrapper
    .rightContent
    .contentWrapper
    .red-btn {
    font-size: 14px;
    padding: 8px 30px;
  }

  #workSection .rightContent.step1Image img {
    max-width: 100%;
  }
  .plan-section-responsive {
    width: 50%;
  }

  #heroSection .rightContent img {
    scale: 1;
  }
}

@media (max-width: 991px) {
  /*************** HOME PAGE RESONSIVE CSS **************/
  #heroSection .heroSectionWrapper .leftContent {
    padding: 40px 0;
  }

  #heroSection .leftContent h1 {
    font-weight: 700;
    font-size: 36px;
    line-height: 49px;
  }

  #heroSection .leftContent p {
    font-size: 17px;
    line-height: 30px;
    padding-right: 0;
    padding: 10px 0;
  }

  #heroSection .leftContent .white-btn {
    padding: 10px 23px;
  }

  #heroSection .leftContent .bannerArrow {
    top: -55px;
    left: 170px;
  }

  #planSection .planHeader h2 {
    font-size: 34px;
    margin: 0 auto 20px auto;
    width: 80%;
  }

  #planSection .planHeader p {
    font-size: 16px;
  }

  #planSection .planCardWrapper .row {
    position: relative;
    /* width: 100%; */
    justify-content: space-between;
  }

  #planSection .planCardWrapper .row .col-md-4 {
    width: 45%;
  }

  #planSection .planCardWrapper .planCard {
    margin-bottom: 10px;
    padding: 18px 12px;
    min-height: 590px;
    width: 315px;
  }

  #planSection .planCard .planCardHeader {
    min-height: 0px;
  }

  #planSection .planCard .planCardHeader p {
    font-size: 18px;
  }

  #planSection .planCard .planCardHeader span {
    font-size: 10px;
    font-weight: 500;
    padding: 4px 8px;
  }

  #planSection .planCard .planCardBody .iconBoxWrapper .iconBox img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }

  #planSection .planCard .planCardBody .iconBoxWrapper .iconBox p {
    font-size: 14px;
    line-height: 18px;
  }

  #planSection .planCard .planCardBody .iconBoxWrapper .iconBox .parameterIcon {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-bottom: 10px;
    font-size: 22px;
  }

  #planSection .planCard .planCardBody .iconBoxWrapper .iconBox {
    padding-right: 15px;
  }

  #planSection .planCard .planCardBody ul li {
    font-size: 14px;
    line-height: 20px;
  }

  #planSection .planCard .planCardBody .priceWrapper .percent p {
    font-size: 24px;
  }

  #planSection .planCard .planCardBody .priceWrapper .price p {
    font-size: 18px;
  }

  #planSection .planCard .planCardFooter a {
    font-size: 14px;
    margin-right: 20px;
  }

  #planSection .planCard .planCardFooter button {
    font-size: 14px;
    padding: 8px 30px;
    margin-left: 20px;
  }

  #pincodeSection .leftContent p {
    font-size: 24px;
    line-height: 34px;
  }

  #pincodeSection .rightContent .pincodeInput {
    width: 100%;
  }

  #quizSection .quizSectionWrapper {
    padding: 100px 0;
  }

  #quizSection .quizSectionWrapper .rightContent p {
    padding-right: 0;
  }

  #quizSection .quizSectionWrapper .rightContent h3 {
    font-size: 32px;
    line-height: 43px;
  }

  #qualitySection .qualitySectionWrapper .leftContent .boxWrapper .box {
    max-width: 150px;
    padding: 25px 0;
  }

  #qualitySection .qualitySectionWrapper .leftContent .boxWrapper .box h4 {
    font-size: 28px;
  }

  #workSection .leftContent .stepWrapper {
    padding: 20px 11px 20px 0;
  }

  #workSection .leftContent .stepWrapper ul li {
    font-size: 14px;
    padding: 8px 10px;
  }

  #workSection .leftContent .stepWrapper ul li.active {
    font-size: 14px;
  }

  #workSection .leftContent .col-md-9 {
    padding-right: 0;
  }

  #workSection .leftContent .para p {
    margin-left: 0;
  }

  #workSection .workHeader {
    margin-bottom: 40px;
    padding-top: 50px;
  }

  #workSection .workHeader h2 {
    font-size: 25px;
  }

  #workSection .workHeader p {
    font-size: 16px;
  }

  #workSection .leftContent .stepWrapper {
    padding: 20px 11px 20px 0;
  }

  #workSection .leftContent .stepWrapper ul li {
    font-size: 14px;
    padding: 8px 10px;
  }

  #workSection .leftContent .stepWrapper ul li.active {
    font-size: 14px;
  }

  #workSection .leftContent .col-md-9 {
    padding-right: 0;
  }

  #workSection .leftContent .para p {
    margin-left: 0;
  }

  #whyChooseSection .whyChooseCard {
    margin-bottom: 32px;
    min-height: 300px;
  }

  #testimonialSection .testimonialCard {
    padding: 15px;
  }

  #testimonialSection .testimonialCard .cardTop img {
    width: 50px;
    height: 50px;
  }

  #testimonialSection .testimonialCard .cardTop .cardTopText h4 {
    font-size: 14px;
  }

  #callBackSection .callBackWrapper {
    padding: 50px;
    background-position: top right;
  }

  #footerSection .footerRightWrapper {
    padding: 33px 0 25px 5px;
  }

  #healthSection .healthSectionWrapper {
    padding: 40px 0;
  }

  #healthSection .leftContent {
    text-align: center;
  }

  #healthSection .leftContent img {
    max-width: 70%;
  }

  #healthSection .rightContent .healthCard {
    background: #f9f9f9;
    box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    padding: 40px 20px;
  }

  #faqPageSection .faqSectionWrapper {
    padding: 70px 0;
  }

  #healthwaySection .healthwaySectionWrapper .leftContent {
    min-height: 410px;
  }

  #healthwaySection .healthwaySectionWrapper .leftContent .contentWrapper {
    padding: 40px 10px;
  }

  #healthwaySection
    .healthwaySectionWrapper
    .leftContent
    .contentWrapper
    .imgContentWrapper
    img {
    height: 256;
    width: 181;
  }

  #healthwaySection .healthwaySectionWrapper .leftContent .contentWrapper p {
    font-size: 14px;
    padding: 40px 10px;
    margin: 35px 0;
    line-height: 21px;
  }

  #healthwaySection .healthwaySectionWrapper .rightContent {
    min-height: 410px;
  }

  #healthwaySection .healthwaySectionWrapper .leftContent table {
    top: 122px;
    left: 50px;
  }

  #healthwaySection .healthwaySectionWrapper .leftContent table tr th,
  #healthwaySection .healthwaySectionWrapper .leftContent table tr td {
    font-size: 12px;
    padding: 8px 20px 5px 20px;
  }

  #healthwaySection .healthwaySectionWrapper .rightContent .contentWrapper {
    padding: 40px 10px;
  }

  #healthwaySection .healthwaySectionWrapper .rightContent .contentWrapper p {
    font-size: 14px;
    padding: 40px 10px;
    margin: 35px 0;
    line-height: 21px;
  }

  #healthwaySection
    .healthwaySectionWrapper
    .rightContent
    .contentWrapper
    .red-btn {
    font-size: 14px;
    padding: 8px 30px;
  }
}

@media (max-width: 767px) {
  /*************** HOME PAGE RESONSIVE CSS **************/
  #heroSection .heroSectionWrapper {
    background-position: 10%;
  }

  #heroSection .heroSectionWrapper .leftContent {
    padding: 40px 0 75px 0;
  }

  #heroSection .leftContent h1 {
    text-align: center;
    font-weight: 800;
    font-size: 28px;
    line-height: 47px;
  }

  #heroSection .leftContent p {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
  }

  #heroSection .leftContent .position-relative {
    text-align: center;
  }

  #heroSection .leftContent .white-btn {
    padding: 12px 28px;
    border-radius: 36px;
  }

  #heroSection .leftContent .bannerArrow img {
    max-width: 70%;
  }

  #heroSection .leftContent .bannerArrow {
    top: 25px;
    left: 210px;
  }

  #planSection .planSectionWrapper {
    padding: 70px 0;
  }

  #planSection .planHeader {
    margin-bottom: 30px;
  }

  #planSection .planHeader h2 {
    font-size: 25px;
    line-height: 38px;
    width: 100%;
  }

  #planSection .planHeader p {
    font-size: 14px;
    line-height: 30px;
  }

  #planSection .planHeader p br {
    display: none;
  }

  #planSection .planCardWrapper .row {
    position: relative;
    /* width: 100%; */
    justify-content: space-between;
  }

  #planSection .planCardWrapper .row .col-md-4 {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  #planSection .planCardWrapper .planCard {
    min-height: 430px;
    margin-bottom: 25px;
    margin-top: 20px;
    width: 368px;
  }

  #planSection .planCard .planCardHeader {
    min-height: 0px;
  }

  #planSection .planCard .planCardHeader p {
    font-size: 15px;
  }

  #planSection .planCard .planCardHeader span {
    font-size: 8px;
  }

  #planSection .planCard .planCardBody .iconBoxWrapper {
    padding: 0;
    min-height: 0px;
  }

  #planSection .planCard .planCardBody .iconBoxWrapper .iconBox:last-child {
    flex-grow: 1;
  }

  #planSection .planCard .planCardBody .iconBoxWrapper .iconBox p {
    font-size: 13px;
  }

  #planSection .planCard .planCardBody ul {
    margin-top: 8px;
    min-height: 0px;
  }

  #planSection .planCard .planCardBody ul li {
    font-size: 12px;
    line-height: 20px;
    padding: 8px 0;
  }

  #planSection .planCard .planCardBody ul li img {
    width: 14px;
    height: 14px;
  }

  #planSection .planCard .planCardBody .priceWrapper {
    margin: 24px 0;
  }

  #planSection .planCard .planCardBody .priceWrapper .percent p {
    font-size: 20px;
    padding: 10px 0;
  }

  #planSection .planCard .planCardBody .priceWrapper .price p {
    font-size: 14px;
    padding: 10px 0;
  }

  #planSection .planCard .planCardFooter a {
    font-size: 12px;
  }

  #planSection .planCard .planCardFooter button {
    font-size: 12px;
    padding: 8px 35px;
  }

  #pincodeSection .leftContent p {
    font-size: 25px;
    line-height: 38px;
    margin-bottom: 30px;
  }

  #pincodeSection .rightContent .pincodeInput > img {
    padding: 13px 10px 13px 10px;
  }

  #quizSection .quizSectionWrapper {
    padding: 70px 0;
  }

  #quizSection .quizSectionWrapper .leftContent {
    text-align: center;
    margin-bottom: 40px;
  }

  #quizSection .quizSectionWrapper .leftContent img {
    max-width: 80%;
  }

  #quizSection .quizSectionWrapper .rightContent h3 {
    font-size: 25px;
    line-height: 38px;
    font-weight: 400;
  }

  #quizSection .quizSectionWrapper .rightContent h3 span br {
    display: none;
  }

  #quizSection .quizSectionWrapper .rightContent p {
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 20px;
  }

  #quizSection .quizSectionWrapper .rightContent a.red-btn {
    padding: 13px 53px;
  }

  #qualitySection .qualitySectionWrapper {
    padding: 70px 0;
  }

  #qualitySection .qualitySectionWrapper .leftContent h3 {
    font-size: 25px;
    line-height: 38px;
    font-weight: 400;
  }

  #qualitySection .qualitySectionWrapper .leftContent .boxWrapper {
    margin-top: 20px;
    justify-content: space-between;
  }

  #qualitySection .qualitySectionWrapper .leftContent .boxWrapper .box {
    flex-basis: 48%;
    max-width: 100%;
    margin: 0;
    margin-bottom: 15px;
  }

  #qualitySection .qualitySectionWrapper .leftContent .boxWrapper .box h4 {
    margin-bottom: 0;
  }

  #qualitySection .qualitySectionWrapper .leftContent .boxWrapper .box p {
    font-size: 10px;
  }

  #qualitySection .qualitySectionWrapper .rightContent {
    padding-bottom: 0;
    text-align: center;
  }

  #healthwaySection .healthwaySectionWrapper .leftContent {
    min-height: 280px;
  }

  #healthwaySection .healthwaySectionWrapper .leftContent .contentWrapper {
    padding: 25px 10px;
    flex-direction: row-reverse;
  }

  #healthwaySection
    .healthwaySectionWrapper
    .leftContent
    .contentWrapper
    .imgContentWrapper
    img {
    height: 256;
    width: 181;
  }

  #healthwaySection .healthwaySectionWrapper .leftContent .contentWrapper h4 {
    margin-bottom: 0;
    font-size: 14px;
  }

  #healthwaySection
    .healthwaySectionWrapper
    .leftContent
    .contentWrapper
    .contentCard
    p {
    font-weight: 600;
    font-size: 9px;
    line-height: 12px;
    color: white;
    padding: 10px 10px;
    border-radius: 11px;
    background: #828282;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    width: 200px;
  }
  .healthwaySectionSpantag {
    width: "200px";
    font-size: "20px";
    line-height: "30px";
    font-weight: 500;
    letter-spacing: 0.9px;
  }

  #healthwaySection
    .healthwaySectionWrapper
    .leftContent
    .contentWrapper
    .red-btn {
    font-size: 12px;
    padding: 5px 20px;
    width: 110px;
  }

  #healthwaySection .healthwaySectionWrapper .rightContent {
    min-height: 250px;
  }

  #healthwaySection .healthwaySectionWrapper .leftContent table {
    top: 40px;
    left: 10px;
  }

  #healthwaySection .healthwaySectionWrapper .leftContent table tr th,
  #healthwaySection .healthwaySectionWrapper .leftContent table tr td {
    font-size: 8px;
    padding: 4px 8px 4px 8px;
  }

  #healthwaySection .healthwaySectionWrapper .rightContent .contentWrapper {
    padding: 2c5px 10px;
    height: 251px;
    width: 69%;
  }

  #healthwaySection .healthwaySectionWrapper .rightContent .contentWrapper h4 {
    margin-bottom: 0;
    font-size: 14px;
  }

  #healthwaySection .healthwaySectionWrapper .rightContent .contentWrapper p {
    font-size: 9px;
    padding: 10px 10px;
    margin: 20px 0;
    line-height: 12px;
  }

  #healthwaySection
    .healthwaySectionWrapper
    .rightContent
    .contentWrapper
    .red-btn {
    font-size: 12px;
    padding: 5px 20px;
  }

  #whyChooseSection .whyChooseSectionWrapper {
    padding: 70px 0 40px 0;
  }

  #whyChooseSection .topHeading h3 {
    font-size: 25px;
    line-height: 48px;
  }

  #whyChooseSection .whyChooseCard {
    padding: 20px 10px;
    text-align: left;
    min-height: 185px;
    margin: 0;
    margin-bottom: 32px;
  }

  #whyChooseSection .whyChooseCard.marginCard {
    margin-top: -95px;
  }

  #whyChooseSection .whyChooseCard img {
    width: 45px;
    height: 45px;
    margin-bottom: 8px;
  }

  #whyChooseSection .whyChooseCard h4 {
    font-size: 11px;
    line-height: 15px;
  }

  #whyChooseSection .whyChooseCard p {
    font-size: 9px;
    line-height: 13px;
  }

  #newsSection .newsSectionWrapper {
    padding: 70px 0;
  }

  #newsSection .newsLogo {
    padding: 8px 20px;
  }

  #newsSection .newsHeader h2 {
    font-size: 25px;
    line-height: 38px;
  }

  #newsSection .newsHeader p {
    font-size: 16px;
    line-height: 29px;
  }

  #newsSection .newsHeader p br {
    display: none;
  }

  #testimonialSection .testimonialCard {
    min-height: 345px;
  }

  #testimonialSection .testimonialCard .cardTop img {
    width: 60px;
    height: 60px;
  }

  #testimonialSection .testimonialCard .cardBody p {
    font-size: 12px;
    line-height: 21px;
  }

  #testimonialSection .testimonialHeader h2 {
    font-size: 25px;
    line-height: 38px;
    margin-bottom: 30px;
  }

  #faqSection .faqSectionWrapper,
  #faqPageSection .faqSectionWrapper {
    padding: 70px 0;
  }

  #faqSection .faqHeader h2,
  #faqPageSection .faqHeader h2 {
    font-size: 25px;
    line-height: 38px;
  }

  #faqSection .accordion-button:not(.collapsed),
  #faqPageSection .accordion-button:not(.collapsed) {
    font-size: 14px !important;
    line-height: 24px;
  }

  #faqSection .accordion-button,
  #faqPageSection .accordion-button {
    font-size: 14px !important;
    line-height: 24px;
    padding: 10px 5px;
  }

  #faqSection .accordion-body p,
  #faqPageSection .accordion-body p {
    font-size: 12px;
    line-height: 20px;
  }

  #faqSection .accordion-body,
  #faqPageSection .accordion-body {
    margin: 0;
    margin-bottom: 10px;
  }

  #faqSection .faqContentWrapper .accordion,
  #faqPageSection .faqContentWrapper .accordion {
    padding: 15px;
  }

  #faqSection .faqContentWrapper {
    margin: 35px 0;
  }

  #callBackSection .callBackSectionWrapper {
    margin: 0;
  }

  #callBackSection .callBackSectionWrapper .container {
    padding: 0;
  }

  #callBackSection .callBackWrapper {
    padding: 70px 20px;
  }

  #callBackSection .callBackHeader h2 {
    font-size: 25px;
    margin-bottom: 10px;
  }

  #callBackSection .callBackHeader p {
    font-size: 16px;
  }

  #callBackSection .callBackHeader {
    margin-bottom: 40px;
  }

  #callBackSection .formWrapper .form-control {
    font-size: 14px;
    padding: 10px 5px 10px 0;
  }

  #callBackSection .fieldWrapper {
    margin-bottom: 25px;
  }

  #footerSection .footerRightWrapper {
    width: 100%;
    padding: 33px 5px 25px 5px;
  }

  #footerSection .footerRightWrapper .top h3 {
    font-size: 25px;
    line-height: 48px;
  }

  #footerSection .footerRightWrapper .bottom p {
    font-size: 14px;
    line-height: 28px;
  }

  #footerSection .footerLeftWrapper {
    padding: 50px 0 60px 0;
    width: 100%;
    margin: 0 5px;
  }

  #footerSection .footerLeftWrapper .leftContent p {
    width: 70%;
    margin-right: auto;
  }

  #footerSection .footerLeftWrapper .rightContent ul {
    padding-left: 0;
  }

  #footerSection .copyright {
    width: 100%;
    text-align: center;
  }

  /*************** STEPS PAGES RESONSIVE CSS **************/
  .stepWrapper .stepWrapperBox {
    padding: 70px 20px 70px 20px;
    border-radius: 0;
  }

  .stepWrapper .stepWrapperBox .stepHeader h1 {
    font-size: 20px;
    line-height: 30px;
  }

  .stepWrapper .stepWrapperBox .stepHeader p {
    font-size: 14px;
  }

  .stepWrapper .stepWrapperBox .stepBody .stepBodyCard {
    margin: 0 5px 22px 5px;
    padding: 20px 7px 10px 7px;
    min-height: 115px;
  }

  .stepWrapper .stepWrapperBox .stepBody .stepBodyCard .image {
    height: 55px;
  }

  .stepWrapper .stepWrapperBox .stepBody .stepBodyCard p {
    font-size: 11px;
    line-height: 15px;
    font-weight: 400;
  }

  .stepWrapper .stepWrapperBox .stepBody .stepBodyCard p span {
    font-size: 8px;
    line-height: 11px;
  }

  .stepWrapper .stepWrapperBox .stepBody {
    min-height: 425px;
  }

  .stepWrapper .stepWrapperBox .stepFooter .btnWrapper .backBtn a {
    width: 160px;
    padding: 12px 0;
    font-size: 14px;
  }

  .stepWrapper .stepWrapperBox .stepFooter .btnWrapper .nextBtn a {
    width: 160px;
    padding: 12px 0;
    font-size: 14px;
  }

  .stepWrapper .stepBody .ratingWrapper .ratingScale span {
    font-size: 12px;
    padding: 8px 4px;
  }

  .stepWrapper .stepBody .formWrapper .form-label {
    font-size: 20px;
  }

  .stepWrapper .stepBody .formWrapper .fieldWrapper .row .col {
    padding: 0 5px;
  }

  .stepWrapper .stepBody .formWrapper .fieldWrapper .genderRadio,
  .stepWrapper .stepBody .formWrapper .fieldWrapper .bmi {
    width: 140px;
  }

  .step7Wrapper .stepHeader img {
    transform: scale(1.1);
  }

  .step7Wrapper .stepHeader {
    overflow: hidden;
    padding-bottom: 20px;
  }

  .step7Wrapper .stepWrapperBox .stepBody {
    padding: 40px 20px 0 20px;
    min-height: 380px !important;
  }

  .step7Wrapper .stepBody h2 {
    font-size: 20px;
    line-height: 30px;
  }

  .step7Wrapper .stepFooter {
    padding: 0 20px 30px 20px;
  }

  .step7Wrapper .textMobile {
    padding: 0 20px 20px 20px;
  }

  .step7Wrapper .textMobile p {
    font-size: 12px;
    line-height: 24px;
    margin-bottom: 0;
  }

  #healthSection .healthSectionWrapper {
    padding: 40px 0;
  }

  #healthSection .leftContent {
    text-align: center;
  }

  #healthSection .leftContent img {
    max-width: 80%;
  }

  #healthSection .rightContent .healthCard {
    background: #f9f9f9;
    box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    padding: 40px 20px;
  }

  #healthSection .rightContent .healthCard .healthCardHeader p {
    font-size: 18px;
    padding-bottom: 0;
  }

  #healthSection .healthCard .healthCardBody .iconBoxWrapper {
    padding: 0;
    justify-content: space-between;
  }

  #healthSection .healthCard .healthCardBody .iconBoxWrapper .iconBox p {
    font-size: 13px;
  }

  #healthSection .healthCard .healthCardBody ul li img {
    width: 14px;
    height: 14px;
  }

  #healthSection .healthCard .healthCardBody .btnWrapper {
    flex-direction: column;
    margin-top: 25px;
  }

  #healthSection .healthCard .healthCardBody .btnWrapper .priceWrapper {
    width: 100%;
    margin-bottom: 21px;
    min-height: 50px;
  }

  #healthSection .healthCard .healthCardBody .priceWrapper .price p br {
    display: none;
  }

  #healthSection .healthCard .healthCardBody .btnContent {
    width: 100%;
  }

  #bloodTestSection .bloodTestSectionWrapper {
    padding: 70px 0;
  }

  #bloodTestSection .bloodTestHeader h2 {
    margin-bottom: 30px;
  }

  #bloodTestSection .bloodTestCard .cardBody p {
    margin: 20px 0 20px 0;
  }

  #bloodTestSection .bloodTestCard .cardBody .tagsWrapper .tags span {
    font-size: 12px;
  }

  #bloodTestSection .bloodTestCard {
    min-height: 270px;
  }

  #faqPageSection .faqHeader h3 {
    margin-top: 30px;
  }

  #quizSection .quizSectionWrapper .rightContent {
    text-align: center;
  }
}

@media screen and (max-width: 414px) {
  #planSection .planCardWrapper .planCard {
    width: 388px;
  }
  #healthwaySection .healthwaySectionWrapper .rightContent .contentWrapper {
    padding: 2c5px 10px;
    height: 251px;
    width: 69%;
  }
}

@media screen and (max-width: 393px) {
  .stepWrapper .stepWrapperBox .stepFooter .btnWrapper .backBtn button {
    width: 140px;
  }

  .stepWrapper .stepWrapperBox .stepFooter .btnWrapper .nextBtn button {
    width: 140px;
  }

  #planSection .planCardWrapper .planCard {
    width: 368px;
  }
}

@media screen and (max-width: 375px) {
  #planSection .planCardWrapper .planCard {
    width: 350px;
  }
  #healthwaySection .healthwaySectionWrapper .rightContent .contentWrapper {
    width: 67%;
  }
}

@media screen and (max-width: 1290px) {
  #planSection .planCardWrapper .planCard {
    width: 410px;
  }
}

.planCardScrollClass {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.planCardScrollClass::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

@media screen and (min-width: 767px) {
  .pincodeInput {
    /* border: 1px solid var(--red-color) !important; */
    background-color: #ffffff;
    width: 80%;
    margin: auto;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 3px;
  }
}

@media screen and (max-width: 767px) {
  .pincodeInput {
    width: 100%;
  }
}

.pincodeInput {
  /* border: 1px solid var(--red-color) !important; */
  background-color: #ffffff;
  /* width: 80%; */
  margin: auto;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 3px;
}
.pincodeInput > img {
  padding: 13px 10px 13px 20px;
}
.pincodeInput button {
  background: none;
  border: 0;
  border-left: 1px solid #dc332e;
  border-left: 1px solid var(--red-color);
  padding: 13px 20px;
}
.pincodeInput input {
  border: 0;
  color: #828282;
  font-size: 14px;
  outline: none;
  width: 100%;
}

/*  */

/* .confettie-wrap {
  background-color: darkslateblue;
  padding: 200px;
} */
/* 
.confettie-wrap h3 {
  margin: 0;
  color: white;
  letter-spacing: 2px;
} */

@media screen and (min-width: 2133px) {
  #healthwaySection .healthwaySectionWrapper .rightContent .contentWrapper {
    height: 449px;
  }
}

#splide01-list {
  text-align: center;
}

/* leanear background loader */
@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.linear-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-size: 1000px 104px;
  height: 200px;
  position: relative;
  overflow: hidden;
}
/*  */
